export const spanishTranslation = {
  // Map
  "ZipCode Search": "Búsqueda por Código Postal",
  "Search for health sites by ZIP Code":
    "Buscar centros de salud por el código postal",
  "Search by City": "Buscar por ciudad",
  "Add New Resource": "Añadir nuevo recurso",
  Resources: "Recursos",
  "Please select one language:": "Seleccione un idioma:",
  "Accepts:": "Aceptar:",
  "Loading...": "Cargando...",
  monday: "lunes",
  tuesday: "martes",
  wednesday: "miércoles",
  thursday: "jueves",
  friday: "viernes",
  saturday: "sábado",
  sunday: "domingo",
  "Adult Clinics": "Clínicas para adultos",
  "Dental Clinics": "Clínicas dentales",
  "Mental Health Clinics": "Clínicas de salud mental",
  "Pediatric Clinics": "Clínicas pediátricas",
  "Specialty Care": "Atención especializada",
  "Add new Pantry": "Añadir una nueva opción",
  Filter: "Filtro",
  "What are you looking for?": "¿Qué está buscando?",
  "School & Summer Meals": "Comidas escolares y de verano",
  "Farmer Markets": "Mercado de agricultores",
  "Food Pantries": "Banco de alimentos",
  "Grocery Store": "Tienda de alimentos",
  "Senior Food Resources": "Recursos alimenticios para mayores",
  "SNAP & WIC offices":
    "Oficinas de SNAP (Supplemental Nutrition Assistance Program) y WIC (Women, Infants, and Children)",
  // Details
  "Suggest an Edit": "Sugerir una edición",
  Contacts: "Contactos",
  "Get Directions": "Cómo llegar",
  Website: "Sitio web",
  "website url": "url del sitio web",
  Call: "Llamar",
  Email: "Correo electrónico",
  "email address": "dirección de correo electrónico",
  "Contact Person": "Persona de contacto",
  "contact person name": "nombre de la persona de contacto",
  "OPERATING DAYS / HOURS": "DÍAS / HORAS DE SERVICIO",
  "For the time being, changes to operating days and hours are only available in the english page.":
    "Por el momento, los cambios en los días y horas de operación solo están disponibles en la página de inglés.",
  "We apologize for any inconvenience this may have caused.":
    "Pedimos disculpas por cualquier inconveniente que esto pueda haber causado.",
  Monday: "Lunes",
  Tuesday: "Martes",
  Wednesday: "Miércoles",
  Thursday: "Jueves",
  Friday: "Viernes",
  Saturday: "Sábado",
  Sunday: "Domingo",
  "e.g. 1st and 3rd every month":
    "Por ejemplo: el primer y tercer día de cada mes",
  Services: "Servicios",
  SERVICES: "Servicios",
  Languages: "Idiomas",
  "languages served/spoken": "idiomas que se hablan/hablados",
  "Services Provided": "Servicios ofrecidos",
  Requirements: "Requisitos",
  "requirements to receive assistance": "requisitos para recibir ayuda",
  "Service Area": "Área de servicio",
  "service area": "área de servicio",
  OTHERS: "OTROS",
  "Payments Accepted": "Métodos de pago aceptados",
  // New Pantry
  "Please provide a valid state.": "Por favor, proporcione un estado válido.",
  "At least one currency payment need to be filled!":
    "¡Es preciso completar al menos un pago de divisas!",
  "You need to choose at least 1 of the filters to be able to submit the form!!":
    "¡Debe elegir al menos 1 de los filtros para poder enviar el formulario!",
  "You need to fill at least one of the following days(hours too)!!":
    "¡Es necesario llenar al menos uno de los siguientes días (horas también)!",
  "Suggest or Create New Resource": "Proponer o crear un nuevo recurso",
  FILTERS: "FILTROS",
  "Choose one or more options that best represent this resource.":
    "Seleccione una o varias opciones que representen mejor este recurso.",
  GENERAL: "GENERAL",
  "Name of Health Resource": "Nombre del recurso médico",
  Latitude: "Latitud",
  latitude: "latitud",
  Longitude: "Longitud",
  longitude: "longitud",
  Address1: "Dirección1",
  address1: "dirección1",
  Address2: "Dirección2",
  address2: "dirección2",
  City: "Ciudad",
  city: "ciudad",
  State: "Estado",
  state: "estado",
  "ZIP Code": "Código postal",
  zipcode: "código postal",
  CONTACTS: "CONTACTOS",
  Phone: "Teléfono",
  "phone number": "número de teléfono",
  "Contact person": "Persona de contacto",
  "We're updating the Operating Days and Hours format to improve your experience within the App.":
    "Hemos actualizado el formato de los días y las horas de operación para mejorar su experiencia dentro de la aplicación.",
  "Please submit suggestions using the fields below.":
    "Envíe sus sugerencias a través de los siguientes campos.",
  Days: "Días",
  Hours: "Horas",
  NOTES: "OBSERVACIONES",
  HOURS: "HORAS",
  to: "a",
  "List services offered": "Lista de servicios ofrecidos",
  "Include telemedicine if provided": "Incluir la telemedicina si se ofrece",
  CURRENCY: "MONEDA",
  "ADDITIONAL INFORMATION": "INFORMACIÓN ADICIONAL",
  Cancel: "Cancelar",
  Create: "Crear",
  "Something went wrong, try again Later!":
    "Algo salió mal, ¡inténtelo de nuevo más tarde!",
  "Your listing on GNR Health Finder was successfully created. Our Admin team will review and push your listing live as soon as possible. Thank you for being a part of the GNR Health Finder network!":
    "Su listado en GNR Health Finder se creó con éxito. Nuestro equipo de administración revisará y presionará su listado en vivo lo antes posible. ¡Gracias por ser parte de la Red GNR Health Finder!",
  "We only allow Newton & Rockdale County valid ZIP Codes":
    "Solo permitimos códigos postales válidos del condado de Newton y Rockdale",
  "We only allow Newton & Rockdale County ZIP Codes":
    "Solo permitimos los códigos postales del condado de Newton y Rockdale",
  // Popup
  "Please tell us what information you need so we can try to add it to our list.":
    "Por favor, díganos qué información necesita para que podamos añadirla a nuestra lista.",
  "What are the biggest barriers you have to getting the healthcare services you need? Check all that apply:":
    "¿Cuáles son los mayores obstáculos que encuentra para obtener los servicios sanitarios que necesita? Marque todas las opciones que correspondan:",
  cost: "costo",
  transportation: "transporte",
  "finding a provider": "encontrar un proveedor",
  "limited service hours": "horario de atención limitado",
  "language barriers": "problemas con el idioma",
  other: "otros",
  "Did you find what you needed here today?":
    "¿Encontró hoy aquí lo que necesitaba?",
  Yes: "Si",
  No: "No",
  "Remind me later": "Recordar más tarde",
  "Don't show again": "No volver a mostrar",
  Submit: "Enviar",
  "Thank you for your help!": "¡Gracias por su ayuda!",
  "Your feedback has been successfully submitted. Your opinion is important for us!":
    "Sus comentarios han sido enviados correctamente. ¡Su opinión es importante para nosotros!",
};
