import React from 'react';
import { makeStyles } from '@material-ui/core';
import MultiLingualContent from '../../services/LanguageContext/MultiLingualContent';

export const weekdays = {
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
};

export function dataToPM(time, type) {
  try {
    if (typeof time == 'object' && time == null) return '';

    if (type === 'from') {
      if (time.length === 0 || time === false || time[0].hoursFrom == null)
        return '';

      time = time[0].hoursFrom;
    } else if (type === 'to') {
      if (time.length === 0 || time === false || time[0].hoursTo == null)
        return '';

      time = time[0].hoursTo;
    }
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  } catch (e) {
    console.log(e);
  }
}

const DaysDiv = {
  Hours: function hours(json) {
    return (
      <div>
        <div style={{ display: json.monday.checked ? 'block' : 'none' }}>
          <b>
            <MultiLingualContent contentString={'Monday'} />
          </b>
          &nbsp;&nbsp;
          {dataToPM(json.monday.hours, 'from')} -{' '}
          {dataToPM(json.monday.hours, 'to')}
        </div>
        <div style={{ display: json.tuesday.checked ? 'block' : 'none' }}>
          <b>
            <MultiLingualContent contentString={'Tuesday'} />
          </b>
          &nbsp;&nbsp;
          {dataToPM(json.tuesday.hours, 'from')} -{' '}
          {dataToPM(json.tuesday.hours, 'to')}
        </div>
        <div style={{ display: json.wednesday.checked ? 'block' : 'none' }}>
          <b>
            <MultiLingualContent contentString={'Wednesday'} />
          </b>
          &nbsp;&nbsp;
          {dataToPM(json.wednesday.hours, 'from')} -{' '}
          {dataToPM(json.wednesday.hours, 'to')}
        </div>
        <div style={{ display: json.thursday.checked ? 'block' : 'none' }}>
          <b>
            <MultiLingualContent contentString={'Thursday'} />
          </b>
          &nbsp;&nbsp;
          {dataToPM(json.thursday.hours, 'from')} -{' '}
          {dataToPM(json.thursday.hours, 'to')}
        </div>
        <div style={{ display: json.friday.checked ? 'block' : 'none' }}>
          <b>
            <MultiLingualContent contentString={'Friday'} />
          </b>
          &nbsp;&nbsp;
          {dataToPM(json.friday.hours, 'from')} -{' '}
          {dataToPM(json.friday.hours, 'to')}
        </div>
        <div style={{ display: json.saturday.checked ? 'block' : 'none' }}>
          <b>
            <MultiLingualContent contentString={'Saturday'} />
          </b>
          &nbsp;&nbsp;
          {dataToPM(json.saturday.hours, 'from')} -{' '}
          {dataToPM(json.saturday.hours, 'to')}
        </div>
        <div style={{ display: json.sunday.checked ? 'block' : 'none' }}>
          <b>
            <MultiLingualContent contentString={'Sunday'} />
          </b>
          &nbsp;&nbsp;
          {dataToPM(json.sunday.hours, 'from')} -{' '}
          {dataToPM(json.sunday.hours, 'to')}
        </div>
      </div>
    );
  },
  //create the svg for the map
};

export function getHoursInfoDetails(details) {
  try {
    let json = JSON.parse(details.operating_days);
    return DaysDiv.Hours(json);
  } catch (e) {
    let days =
      details.operating_days !== undefined ? details.operating_days : '';
    let hors =
      details.operating_hours !== undefined ? details.operating_hours : '';
    if (hors !== '' || days !== '') {
      return days + '\n\r' + hors;
    }
  }
}

// function parseDay(day, name) {
//   if (day.checked === true) {
//     if (day.hours.length > 0) {
//       return (
//         <p>
//           <b>{name}</b>
//           {dataToPM(day.hours, 'from')}
//           {' - '}
//           {dataToPM(day.hours, 'to')}
//         </p>
//       );
//     }
//   }
// }

export const useStyles = makeStyles((theme) => ({
  day_avaiablee: {
    width: '100%',
    fontSize: '11px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '& .dayy': {
      height: '23px',
      width: 'auto',
      backgroundColor: '#F27866',
      color: '#FFF',
      padding: '4px',
      borderRadius: '2px',
      display: 'inline-block',
      textAlign: 'center',
      marginRight: '5px',
      marginTop: '4px',
    },
    '& .hour': {
      verticalAlign: 'middle',
    },
    '& .hourr': {
      width: '100%',
      fontSize: '11px',
      fontWeight: '500',
    },
  },
  provider_info_containerr: {
    width: '240px',
    minHeight: '40px',
    backgroundColor: 'white',
    fontFamily: 'Roboto',
    marginBottom: '10px',
  },
  providerr: {
    padding: 0,
    margin: 0,
    overflow: 'hidden',
    width: '80%',
    '& p': {
      margin: 0,
      fontSize: '12px',
      color: 'black',
    },
    '& .title': {
      padding: 0,
      margin: 0,
      color: '#373a3c',
      fontSize: '14px',
      fontWeight: 900,
      marginBottom: '.4rem',
      textAlign: 'left',
    },
    '& .time': {
      textAlign: 'left',
    },
    '& .linkPopup': {
      height: '100%',
      width: '100%',
      display: 'block',
      position: 'absolute',
      left: '0px',
      top: '0px',
    },
    '& .details': {
      position: 'absolute',
      top: '56%',
      left: '78%',
      transform: 'translateY(-50%)',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '& .details .arrow': {
      left: '36px',
      position: 'relative',
      bottom: '5px',
      display: 'block',
    },
    '& .details .detail': {
      textDecoration: 'none',
      color: '#31b575',
      textTransform: 'capitalize',
    },
  },
  currency: {
    color: 'white',
    width: 'auto',
    height: '17px',
    borderRadius: '5px',
    backgroundColor: '#0B1E32',
    marginLeft: '4px',
    textAlign: 'center',
    padding: '0px 5px 5px 5px',
    marginTop: '5px',
  },
  currencyText: {
    height: '12px',
    fontSize: '10px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#ffffff',
  },
  pCurrency: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const StaticImages = {
  Search: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <path
        fill="#FFF"
        d="M11.24 9.834L8.71 7.305c.43-.684.68-1.493.68-2.36C9.39 2.49 7.4.5 4.945.5S.5 2.49.5 4.945s1.99 4.444 4.445 4.444c.944 0 1.819-.295 2.538-.797l2.5 2.5c.173.173.4.26.628.26.228 0 .455-.087.629-.26.347-.348.347-.91 0-1.258zM4.945 7.95c-1.66 0-3.005-1.345-3.005-3.004 0-1.66 1.345-3.005 3.005-3.005 1.659 0 3.004 1.346 3.004 3.005 0 1.66-1.345 3.004-3.004 3.004z"
      />
    </svg>
  ),
  Phone: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <path
        fill="#F27866"
        fillRule="nonzero"
        d="M30.856 11.29c.968 0 1.755.788 1.755 1.756v23.908c0 .968-.787 1.756-1.756 1.756h-12.97a1.758 1.758 0 0 1-1.756-1.756V13.046c0-.968.788-1.756 1.756-1.756zm0 1.17H17.884a.586.586 0 0 0-.586.586v23.908c0 .323.263.585.586.585h12.97a.586.586 0 0 0 .586-.585V13.046a.586.586 0 0 0-.586-.585zM24.37 33.684c.839 0 1.521.682 1.521 1.52 0 .84-.682 1.522-1.52 1.522a1.523 1.523 0 0 1-1.522-1.521c0-.839.682-1.521 1.521-1.521zm0 1.053a.468.468 0 1 0 .001.937.468.468 0 0 0 0-.937zm5.322-19.114c.29 0 .526.236.526.527v16.134c0 .291-.236.527-.526.527H19.049a.527.527 0 0 1-.527-.527V16.15c0-.29.236-.527.527-.527zm-.527 1.054h-9.59v15.08h9.59v-15.08zm-4.636 5.604a.585.585 0 0 1 .827.827l-2.81 2.812a.584.584 0 0 1-.828 0 .585.585 0 0 1 0-.828zm1.666 1.629a.585.585 0 0 1 .828.828l-1.12 1.12a.584.584 0 0 1-.828 0 .585.585 0 0 1 0-.828zm-3.736-10.467a.584.584 0 1 1 0 1.168.584.584 0 0 1 0-1.168zm4.406.014a.556.556 0 1 1 0 1.112h-2.7a.556.556 0 0 1 0-1.112z"
      />
    </svg>
  ),
  Person: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <path
        fill="#F27866"
        fillRule="nonzero"
        d="M27.5 14c.825 0 1.5.675 1.5 1.5v1.8c0 .25-.025.475-.1.7h6.6c1.375 0 2.5 1.125 2.5 2.5v12c0 1.375-1.125 2.5-2.5 2.5h-20a2.507 2.507 0 0 1-2.5-2.5v-12c0-1.375 1.125-2.5 2.5-2.5h6.6c-.05-.225-.1-.475-.1-.7v-1.8c0-.825.675-1.5 1.5-1.5zm-4.85 4.975c-.05.025-.1.025-.15.025h-7c-.825 0-1.5.675-1.5 1.5v12c0 .825.675 1.5 1.5 1.5h20c.825 0 1.5-.675 1.5-1.5v-12c0-.825-.675-1.5-1.5-1.5h-7.15l-1.675 2.1c-.3.375-.725.575-1.175.575-.475 0-.9-.225-1.175-.575zM20.25 21c1.65 0 3 1.35 3 3 0 .725-.25 1.5-.7 2.125 1.05.325 1.925.85 2.35 2.225l.425 1.55c.075.275.05.55-.125.775a.876.876 0 0 1-.7.35h-8.375a.876.876 0 0 1-.7-.35.936.936 0 0 1-.125-.775l.425-1.525c.425-1.375 1.275-1.9 2.25-2.225-.45-.65-.725-1.425-.725-2.15 0-1.65 1.35-3 3-3zm1.525 5.95c-.45.35-.95.55-1.525.55-.55 0-1.05-.2-1.5-.525-1.15.275-1.775.65-2.075 1.675L16.3 30h8.025l-.375-1.375c-.325-1.025-.925-1.35-2.175-1.675zM29.75 26c.275 0 .5.225.5.5s-.225.5-.5.5H26.5a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm3 0c.275 0 .5.225.5.5s-.225.5-.5.5H32a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm-12.5-4c-1.1 0-2 .9-2 2 0 .7.375 1.5.9 2l.1.1c.3.25.625.4 1 .4 1.075 0 2-1.375 2-2.5 0-1.1-.9-2-2-2zm14.25 2c.275 0 .5.225.5.5s-.225.5-.5.5h-8a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5zm-7-9h-4c-.275 0-.5.225-.5.5v1.8c0 .325.125.675.35.95l1.8 2.225c.125.15.3.175.375.175.075 0 .25 0 .375-.175l1.775-2.225v-.025l.081-.112c.152-.231.244-.513.244-.813v-1.8c0-.275-.225-.5-.5-.5zm-1.25 1c.275 0 .5.225.5.5s-.225.5-.5.5h-1.5a.501.501 0 0 1-.5-.5c0-.275.225-.5.5-.5z"
      />
    </svg>
  ),
  Email: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <path
        fill="#F27866"
        fillRule="nonzero"
        d="M24.696 14.611a.537.537 0 0 1 .612.001l2.938 2.043h3.453c.296 0 .537.24.537.537v2.237l3.001 2.086a.535.535 0 0 1 .212.298.31.31 0 0 1 .019.107l.016 11.622c0 .296-.24.537-.536.537l-19.878.028a.537.537 0 0 1-.538-.537l-.016-11.64.001-.01a.536.536 0 0 1 .231-.433l3.016-2.085v-2.21c0-.297.24-.537.537-.537h3.438zm.312 11.93l-8.367 6.49 16.705-.023-8.338-6.468zm-9.417-3.586l.014 9.52 6.484-5.03-6.498-4.49zm18.804-.026l-6.481 4.506 6.494 5.038-.013-9.544zm-3.233-5.2H18.838v6.163l4.142 2.863 1.699-1.318a.537.537 0 0 1 .65-.006l.008.006 1.687 1.308 4.138-2.876v-6.14zm-2.527 4.94a.537.537 0 0 1 0 1.074h-7.27a.537.537 0 0 1 0-1.074zm-10.87-1.961l-1.768 1.221 1.767 1.221v-2.442zm14.47.029v2.385l1.717-1.193-1.716-1.192zm-3.6-.86a.537.537 0 0 1 0 1.074h-7.27a.537.537 0 0 1 0-1.074zm-3.634-4.17l-1.372.948h2.736L25 15.707z"
      />
    </svg>
  ),
  Website: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <path
        fill="#F27866"
        fillRule="nonzero"
        d="M35.636 14.516c.806 0 1.46.655 1.46 1.46v14.688c0 .805-.654 1.46-1.46 1.46h-7.29v1.063c0 .974.794 1.767 1.768 1.767h1.606a.558.558 0 0 1 0 1.115H18.28a.558.558 0 0 1 0-1.115h1.606c.974 0 1.767-.793 1.767-1.767v-1.063h-7.29c-.805 0-1.46-.655-1.46-1.46V15.977c0-.806.655-1.46 1.46-1.46zm-8.404 17.609h-4.463v1.062c0 .666-.228 1.279-.608 1.767h5.678a2.867 2.867 0 0 1-.607-1.767v-1.062zm8.75-4.392H14.018v2.93c0 .191.154.346.345.346h21.272c.19 0 .345-.155.345-.345v-2.931zM25 28.769a.602.602 0 1 1 0 1.204.602.602 0 0 1 0-1.204zm10.636-13.137H14.364a.346.346 0 0 0-.345.345v10.64H35.98v-10.64a.346.346 0 0 0-.345-.345zm-10.468 2.727a.558.558 0 0 1 .788.788l-3.941 3.942a.556.556 0 0 1-.789 0 .558.558 0 0 1 0-.789zm2.336 2.284a.558.558 0 0 1 .789.789l-1.57 1.57a.556.556 0 0 1-.79 0 .558.558 0 0 1 0-.79z"
      />
    </svg>
  ),
  Address: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <path
        fill="#F27866"
        fillRule="nonzero"
        d="M26.072 9.677c.307 0 .556.25.556.556v2.159a.556.556 0 0 1-.556.555h-.893v.723c6.206.29 11.165 5.43 11.165 11.706 0 6.463-5.258 11.72-11.72 11.72-6.463 0-11.72-5.257-11.72-11.72 0-6.276 4.958-11.415 11.164-11.706v-.723h-.894a.556.556 0 0 1-.555-.555v-2.159c0-.307.248-.556.555-.556zm-1.448 5.15c-5.818 0-10.55 4.732-10.55 10.55 0 5.817 4.732 10.55 10.55 10.55 5.817 0 10.55-4.733 10.55-10.55 0-5.818-4.733-10.55-10.55-10.55zm0 1.967c4.732 0 8.582 3.85 8.582 8.582 0 4.732-3.85 8.582-8.582 8.582-4.732 0-8.582-3.85-8.582-8.582 0-4.732 3.85-8.582 8.582-8.582zm.555 1.133v.716a.556.556 0 0 1-1.111 0v-.716a7.482 7.482 0 0 0-6.894 6.894h.716a.556.556 0 1 1 0 1.111h-.716a7.482 7.482 0 0 0 6.894 6.894v-.716a.556.556 0 1 1 1.111 0v.716a7.482 7.482 0 0 0 6.894-6.894h-.716a.556.556 0 0 1 0-1.111h.716a7.482 7.482 0 0 0-6.894-6.894zm2.914 3.244a.556.556 0 0 1 .736.737l-2.312 5.086-.006.012a.585.585 0 0 1-.014.025l-.012.022-.007.011-.008.012-.017.024-.014.017a.56.56 0 0 1-.075.075l-.008.007-.009.006a.522.522 0 0 1-.025.018l-.022.015-.022.012c-.008.005-.016.01-.025.013l-.012.007-5.086 2.311a.556.556 0 0 1-.736-.736l2.311-5.086.007-.012a.58.58 0 0 1 .013-.025l.012-.022.015-.023.018-.024.013-.017a.561.561 0 0 1 .075-.075l.017-.013.025-.019.022-.014.022-.013a.532.532 0 0 1 .025-.012l.013-.007zM23.41 24.95l-1.367 3.006 3.006-1.367-1.64-1.64zm3.792-2.153l-3.006 1.366 1.64 1.64 1.366-3.006zm-1.686-12.008H23.73v1.047h1.787v-1.047z"
      />
    </svg>
  ),
  Arrow: (
    <svg
      className="arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="12"
      viewBox="0 0 8 12"
    >
      <path
        fill="#0B1E32"
        fillRule="evenodd"
        d="M1.55.192A.673.673 0 0 0 1.078 0a.673.673 0 0 0-.474.192L.2.585a.648.648 0 0 0 0 .928l4.58 4.485-4.585 4.489a.645.645 0 0 0 0 .928l.401.393a.673.673 0 0 0 .475.192c.179 0 .347-.068.474-.192l5.459-5.345a.646.646 0 0 0 .195-.465.645.645 0 0 0-.195-.466L1.55.192"
      />
    </svg>
  ),
  CloseButton: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 18 18"
    >
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M13.4 3.5l1.1 1.1-4.401 4.399 4.4 4.4-1.1 1.1L9 10.1l-4.4 4.4L3.5 13.4 7.9 9l-4.4-4.4L4.6 3.5l4.399 4.4 4.4-4.4z"
      />
    </svg>
  ),
  OpenButton: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 18 18"
    >
      <path
        fill="#FFF"
        d="M7.444 14.333h3.112v-1.555H7.444v1.555zM2 5v1.556h14V5H2zm2.333 5.444h9.334V8.89H4.333v1.555z"
      />
    </svg>
  ),
  MapListButton: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        fill="#FFF"
        d="M11.348 2.256L6.68 4.006 2 2v11.769l4.652 1.994 4.667-1.75L16 16.02V4.25l-4.652-1.994zM6.083 14.25L3.167 13V9.02l2.916 1.25v3.98zm0-5.25L3.167 7.75V3.77l2.916 1.25V9zm4.667 3.98l-3.5 1.313v-4.004l3.5-1.313v4.004zm0-5.25l-3.5 1.313V5.039l3.5-1.313V7.73zm4.083 6.52L11.917 13V8.962l2.916 1.042v4.246zm0-5.532v.047l-2.916-1.042V3.77l2.916 1.25v3.699z"
      />
    </svg>
  ),
  DefaultIconMap:
    'https://res.cloudinary.com/dsgfmbsii/image/upload/v1595517848/6-snap-wic-offices-copy_qh8cr8.svg',
  AddNewResourceMobile: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 28 28"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-337 -8) translate(337 8)">
            <circle cx="14" cy="14" r="14" fill="#FFC10E" />
            <path
              fill="#FFF"
              fillRule="nonzero"
              d="M18.929 12.929h-3.643c-.119 0-.215-.096-.215-.215V9.071C15.071 8.48 14.591 8 14 8c-.592 0-1.071.48-1.071 1.071v3.643c0 .119-.096.215-.215.215H9.071C8.48 12.929 8 13.409 8 14c0 .592.48 1.071 1.071 1.071h3.643c.119 0 .215.096.215.215v3.643c0 .591.48 1.071 1.071 1.071.592 0 1.071-.48 1.071-1.071v-3.643c0-.119.096-.215.215-.215h3.643c.591 0 1.071-.48 1.071-1.071 0-.592-.48-1.071-1.071-1.071z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
  SearchIcon: (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <rect
            id="Rectangle"
            fill="#00263A"
            x="0"
            y="0"
            width="22"
            height="22"
            rx="10"
          ></rect>
          <path
            d="M16.2395111,14.8342159 L13.7105354,12.305055 C14.140227,11.6209749 14.3896148,10.8124324 14.3896148,9.94471477 C14.3896148,7.48982387 12.3995131,5.5 9.94471477,5.5 C7.48991647,5.5 5.5,7.48982387 5.5,9.94471477 C5.5,12.3996983 7.48982387,14.3893369 9.94471477,14.3893369 C10.8890176,14.3893369 11.7636807,14.0941092 12.4834141,13.5924628 L14.9822928,16.0915268 C15.1559289,16.2649777 15.3835543,16.351379 15.6109019,16.351379 C15.8385273,16.351379 16.0658749,16.2649777 16.2397889,16.0915268 C16.5867833,15.7441619 16.5867833,15.1814882 16.2395111,14.8342159 Z M9.94471477,12.9493146 C8.28549412,12.9493146 6.94030021,11.6042132 6.94030021,9.94489999 C6.94030021,8.28558672 8.28549412,6.94039282 9.94471477,6.94039282 C11.604028,6.94039282 12.9491293,8.28558672 12.9491293,9.94489999 C12.9491293,11.6042132 11.604028,12.9493146 9.94471477,12.9493146 Z"
            id="search-icon"
            fill="#FFFFFF"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  ),
  Back: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#0B1E32">
          <g transform="translate(-62 -80) translate(62 80)">
            <rect width="25" height="25" opacity=".1" rx="12.5" />
            <path
              fillRule="nonzero"
              d="M18.905 11.541L9.46 11.541 12.766 8.235 11.349 6.818 5.682 12.485 11.349 18.152 12.766 16.736 9.46 13.43 18.905 13.43z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
};
