// import React from 'react';
import ReactGa from 'react-ga4';
import ReactDOM from 'react-dom';

export function detailsClick(id, name) {
  ReactGa.event({
    category: 'place_detail',
    action: `Pantry: [${id}] (${name})`,
  });
}

export function zipCodeSearch() {
  let address = ReactDOM.findDOMNode(
    document.getElementById('zipSearch')
  ).value;
  address = address.match(/^\d{5}(-\d{4})?/);
  if (address !== null) {
    ReactGa.event({
      category: 'searched_zip_code',
      action: 'ZIPCode: ' + address[0],
      value: parseInt(address[0]),
    });
  }
}

export function pantryClick(pantryID, name) {
  ReactGa.event({
    category: 'select_map_pin',
    action: `Pantry: [${pantryID}] (${name})`,
  });
}

export function filterClick(name) {
  ReactGa.event({
    category: 'filter_click',
    action: 'Filter: ' + name,
  });
}

export function suggestClick(category, action) {
  ReactGa.event({
    category: category,
    action: action,
  });
}
