import React, { useEffect, useState } from "react";
import "./App.css";
import Routes from "./routes";
import ReactGa from "react-ga4";
import { LanguageContext } from "./services/LanguageContext/MultiLingualContext";
import { translations } from "./services/LanguageContext/translations";

let head = document.getElementsByTagName("head")[0],
  meta = document.createElement("meta");

meta.name = "viewport";
meta.content = "width=device-width,initial-scale=1";
head.appendChild(meta);

function App() {
  useEffect(() => {
    // ReactGa.initialize("G-BN6MKNPNSE", {
    //   gaOptions: {
    //     cookieName: "_ga_mobtag",
    //     cookieFlags: "SameSite=None; Secure",
    //   },
    // }); //dev
    ReactGa.initialize("G-0ZCXLNNW43", {
      gaOptions: {
        cookieName: "_ga_mobtag",
        cookieFlags: "SameSite=None; Secure",
      },
    }); //prod
    // ReactGa.pageview('/');
    ReactGa.send({ hitType: "pageView", page: "/" });
  }, []);
  const [language, setLanguage] = useState(
    localStorage.getItem("language")
      ? JSON.parse(localStorage.getItem("language"))
      : "EN"
  );
  function toggleLanguage(language) {
    language !== undefined &&
      translations.hasOwnProperty(language) &&
      setLanguage(language);
  }
  return (
    <div className="App">
      <LanguageContext.Provider value={{ language, toggleLanguage }}>
        <Routes />
      </LanguageContext.Provider>
    </div>
  );
}

export default App;
