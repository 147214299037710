/* eslint-disable array-callback-return */
import ReactDOM from "react-dom";
import { createSuggest } from "../../services/fetch";
// import { consoleFF } from '../helpers';

let history;

let days = {
  monday: { notes: null, hours: [], checked: false },
  tuesday: { notes: null, hours: [], checked: false },
  wednesday: { notes: null, hours: [], checked: false },
  thursday: { notes: null, hours: [], checked: false },
  friday: { notes: null, hours: [], checked: false },
  saturday: { notes: null, hours: [], checked: false },
  sunday: { notes: null, hours: [], checked: false },
};

export let json = {
  //operating_hours: null,
  //startDate: null,
  //endDate: null
};

/**
 *
 * @param histor
 * @param pantryId only defined if its a suggest not a creation
 */
export const prepareSubmit = (
  histor,
  pantryId,
  language_id,
  override = false
) => {
  json = {};
  history = histor;
  if (pantryId !== undefined) {
    json.pantry_provider_id = parseInt(pantryId);
  }
  //json.name = Value("name-field") !== "" ? Value("name-field") : null
  json.sourceFrom = "gnr-extension";
  if (Value("name-field") !== "") json.name = Value("name-field");
  if (Value("latitude") !== "") json.latitude = parseFloat(Value("latitude"));
  if (Value("longitude") !== "")
    json.longitude = parseFloat(Value("longitude"));
  if (Value("address1-field") !== "") json.address1 = Value("address1-field");
  if (Value("address2-field") !== "") json.address2 = Value("address2-field");
  if (Value("city-field") !== "") json.city = Value("city-field");
  if (Value("state-field") !== "") json.state = Value("state-field");
  if (Value("zip-field") !== "") json.zip_code = Value("zip-field");
  if (Value("phone-field") !== "") json.phone_number = Value("phone-field");
  if (Value("website-field") !== "") json.url = Value("website-field");
  if (Value("email-field") !== "") json.email = Value("email-field");
  if (Value("contact-field") !== "")
    json.contact_person = Value("contact-field");
  if (Value("service-field") !== "") json.serviceArea = Value("service-field");
  if (Value("languages-field") !== "")
    json.languages = Value("languages-field");
  if (Value("services-field") !== "") json.services1 = Value("services-field");
  if (Value("requirements-field") !== "")
    json.requirements = Value("requirements-field");
  if (Value("comment") !== "") json.comment = Value("comment");
  json.services3 = prepareFilters();
  json.language_id = language_id;
  currency();
  prepareDays(false, override);
};

function prepareFilters() {
  let filters = [];
  let ff = document.getElementsByClassName("filterChoice");
  for (let i = 0; i < ff.length; i++) {
    if (ff[i].checked) {
      //console.log(ff[i].getAttribute("type"));
      filters.push(ff[i].getAttribute("data-id"));
    }
  }
  filters = filters.join(",");
  return "{'filters': '" + filters + "'}";
}

export function prepareDays(check = false, override = false) {
  days.monday.checked = ReactDOM.findDOMNode(
    document.getElementById("monday")
  ).checked;
  days.tuesday.checked = ReactDOM.findDOMNode(
    document.getElementById("tuesday")
  ).checked;
  days.wednesday.checked = ReactDOM.findDOMNode(
    document.getElementById("wednesday")
  ).checked;
  days.thursday.checked = ReactDOM.findDOMNode(
    document.getElementById("thursday")
  ).checked;
  days.friday.checked = ReactDOM.findDOMNode(
    document.getElementById("friday")
  ).checked;
  days.saturday.checked = ReactDOM.findDOMNode(
    document.getElementById("saturday")
  ).checked;
  days.sunday.checked = ReactDOM.findDOMNode(
    document.getElementById("sunday")
  ).checked;
  //notes
  days.monday.notes = ReactDOM.findDOMNode(
    document.getElementById("mondayNotes")
  ).value;
  days.tuesday.notes = ReactDOM.findDOMNode(
    document.getElementById("tuesdayNotes")
  ).value;
  days.wednesday.notes = ReactDOM.findDOMNode(
    document.getElementById("wednesdayNotes")
  ).value;
  days.thursday.notes = ReactDOM.findDOMNode(
    document.getElementById("thursdayNotes")
  ).value;
  days.friday.notes = ReactDOM.findDOMNode(
    document.getElementById("fridayNotes")
  ).value;
  days.saturday.notes = ReactDOM.findDOMNode(
    document.getElementById("saturdayNotes")
  ).value;
  days.sunday.notes = ReactDOM.findDOMNode(
    document.getElementById("sundayNotes")
  ).value;
  prepareHours(false, override);
  if (check === true) return days;
}

function prepareHours(check, override = false) {
  if (Value("mondayFrom") !== "" || Value("mondayTo") !== "")
    days.monday.hours[0] = {
      hoursFrom: Value("mondayFrom"),
      hoursTo: Value("mondayTo"),
    };
  if (Value("tuesdayFrom") !== "" || Value("tuesdayTo") !== "")
    days.tuesday.hours[0] = {
      hoursFrom: Value("tuesdayFrom"),
      hoursTo: Value("tuesdayTo"),
    };
  if (Value("wednesdayFrom") !== "" || Value("wednesdayTo") !== "")
    days.wednesday.hours[0] = {
      hoursFrom: Value("wednesdayFrom"),
      hoursTo: Value("wednesdayTo"),
    };
  if (Value("thursdayFrom") !== "" || Value("thursdayTo") !== "")
    days.thursday.hours[0] = {
      hoursFrom: Value("thursdayFrom"),
      hoursTo: Value("thursdayTo"),
    };
  if (Value("fridayFrom") !== "" || Value("fridayTo") !== "")
    days.friday.hours[0] = {
      hoursFrom: Value("fridayFrom"),
      hoursTo: Value("fridayTo"),
    };
  if (Value("saturdayFrom") !== "" || Value("saturdayTo") !== "")
    days.saturday.hours[0] = {
      hoursFrom: Value("saturdayFrom"),
      hoursTo: Value("saturdayTo"),
    };
  if (Value("sundayFrom") !== "" || Value("sundayTo") !== "")
    days.sunday.hours[0] = {
      hoursFrom: Value("sundayFrom"),
      hoursTo: Value("sundayTo"),
    };
  if (check === true) {
    return days;
  } else {
    if (
      json.address1 !== undefined &&
      json.name !== undefined &&
      json.city !== undefined
    ) {
      json.operating_days = JSON.stringify(days);
      //console.log(days)
      //console.log(json)
      createSuggest(json, history, null, override);
    }
  }
}

function currency() {
  let curr = [];
  if (ReactDOM.findDOMNode(document.getElementById("snap"))?.checked)
    curr.push("SNAP");
  if (ReactDOM.findDOMNode(document.getElementById("wic"))?.checked)
    curr.push("WIC");
  if (ReactDOM.findDOMNode(document.getElementById("sfmnp"))?.checked)
    curr.push("SFMNP");
  //if(ReactDOM.findDOMNode(document.getElementById("fcrc")).checked) curr.push("FCRC")
  if (ReactDOM.findDOMNode(document.getElementById("mdc"))?.checked)
    curr.push("mdc");
  if (ReactDOM.findDOMNode(document.getElementById("mcr"))?.checked)
    curr.push("mcr");
  if (ReactDOM.findDOMNode(document.getElementById("pcr"))?.checked)
    curr.push("pcr");
  if (ReactDOM.findDOMNode(document.getElementById("un"))?.checked)
    curr.push("un");
  if (ReactDOM.findDOMNode(document.getElementById("ssf"))?.checked)
    curr.push("ssf");
  if (ReactDOM.findDOMNode(document.getElementById("sp"))?.checked)
    curr.push("sp");
  json.currency = curr.join(",");
}

export function Value(id) {
  return ReactDOM.findDOMNode(document.getElementById(id)).value;
}

function fillValue(value, id) {
  if (value === null || value === undefined) return;

  ReactDOM.findDOMNode(document.getElementById(id)).value = value;
}

function fillCheck(value, id) {
  if (value === null || value === undefined) return;

  ReactDOM.findDOMNode(document.getElementById(id)).checked = value;
}

function fillHtml(value, id) {
  if (value === null || value === undefined) return;

  ReactDOM.findDOMNode(document.getElementById(id)).innerHTML = value;
}

function checkHours(value, id) {
  if (value.length > 0) {
    fillValue(value[0].hoursFrom, id + "From");
    fillValue(value[0].hoursTo, id + "To");
  }
}

export function fillFields(data) {
  try {
    fillValue(data.name, "name-field");
    fillValue(data.latitude, "latitude");
    fillValue(data.longitude, "longitude");
    fillValue(data.address1, "address1-field");
    fillValue(data.address2, "address2-field");
    fillValue(data.city, "city-field");
    fillValue(data.state, "state-field");
    fillValue(data.zip_code, "zip-field");
    fillValue(data.phone_number, "phone-field");
    // fillValue(data.website, 'website-field');
    fillValue(data.url, "website-field");
    fillValue(data.email, "email-field");
    fillValue(data.contact_person, "contact-field");
    fillValue(data.serviceArea, "service-field");
    fillValue(data.languages, "languages-field");
    fillValue(data.services1, "services-field");
    fillValue(data.requirements, "requirements-field");
    fillValue(data.comment, "comment");
    if (
      data.filter_id !== undefined &&
      !data.filter_id.some((filter) => filter === null)
    ) {
      data.filter_id.map((filterId) => {
        document.querySelector(
          ".filterChoice[data-id='" + filterId + "']"
        ).checked = true;
      });
    }

    jsonDays(data);
    //currency
    if (data.currency !== undefined) {
      let currency = data.currency.split(",");
      if (currency.find((f) => f === "mdc")) fillCheck(true, "mdc");
      if (currency.find((f) => f === "mcr")) fillCheck(true, "mcr");
      if (currency.find((f) => f === "pcr")) fillCheck(true, "pcr");
      if (currency.find((f) => f === "un")) fillCheck(true, "un");
      if (currency.find((f) => f === "ssf")) fillCheck(true, "ssf");
      if (currency.find((f) => f === "sp")) fillCheck(true, "sp");
    }
  } catch (e) {
    console.log(e);
  }
}

function jsonDays(data) {
  let json;
  try {
    json = JSON.parse(data.operating_days);
  } catch (e) {
    fillHtml(data.operating_hours, "operatingHours");
    fillHtml(data.operating_days, "operatingDays");
    return false;
  }
  //checks
  fillCheck(json.monday.checked, "monday");
  fillCheck(json.tuesday.checked, "tuesday");
  fillCheck(json.wednesday.checked, "wednesday");
  fillCheck(json.thursday.checked, "thursday");
  fillCheck(json.friday.checked, "friday");
  fillCheck(json.saturday.checked, "saturday");
  fillCheck(json.sunday.checked, "sunday");
  //notes
  fillValue(json.monday.notes, "mondayNotes");
  fillValue(json.tuesday.notes, "tuesdayNotes");
  fillValue(json.wednesday.notes, "wednesdayNotes");
  fillValue(json.thursday.notes, "thursdayNotes");
  fillValue(json.friday.notes, "fridayNotes");
  fillValue(json.saturday.notes, "saturdayNotes");
  fillValue(json.sunday.notes, "sundayNotes");
  //timers
  checkHours(json.monday.hours, "monday");
  checkHours(json.tuesday.hours, "tuesday");
  checkHours(json.wednesday.hours, "wednesday");
  checkHours(json.thursday.hours, "thursday");
  checkHours(json.friday.hours, "friday");
  checkHours(json.saturday.hours, "saturday");
  checkHours(json.sunday.hours, "sunday");
}
