export const englishTranslation = {
  // Map
  "ZipCode Search": "ZipCode Search",
  "Search for health sites by ZIP Code": "Search for health sites by ZIP Code",
  "Search by City": "Search by City",
  "Add New Resource": "Add New Resource",
  Resources: "Resources",
  "Please select one language:": "Please select one language:",
  "Accepts:": "Accepts:",
  "Loading...": "Loading...",
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday",
  sunday: "sunday",
  "Adult Clinics": "Adult Clinics",
  "Dental Clinics": "Dental Clinics",
  "Mental Health Clinics": "Mental Health Clinics",
  "Pediatric Clinics": "Pediatric Clinics",
  "Specialty Care": "Specialty Care",
  "Add new Pantry": "Add new Pantry",
  Filter: "Filter",
  "What are you looking for?": "What are you looking for?",
  "School & Summer Meals": "School & Summer Meals",
  "Farmer Markets": "Farmer Markets",
  "Food Pantries": "Food Pantries",
  "Grocery Store": "Grocery Store",
  "Senior Food Resources": "Senior Food Resources",
  "SNAP & WIC offices": "SNAP & WIC offices",
  // Details
  "Suggest an Edit": "Suggest an Edit",
  Contacts: "Contacts",
  "Get Directions": "Get Directions",
  Website: "Website",
  "website url": "website url",
  Call: "Call",
  Email: "Email",
  "email address": "email address",
  "Contact Person": "Contact Person",
  "contact person name": "contact person name",
  "OPERATING DAYS / HOURS": "OPERATING DAYS / HOURS",
  "For the time being, changes to operating days and hours are only available in the english page.":
    "For the time being, changes to operating days and hours are only available in the english page.",
  "We apologize for any inconvenience this may have caused.":
    "We apologize for any inconvenience this may have caused.",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  "e.g. 1st and 3rd every month": "e.g. 1st and 3rd every month",
  Services: "Services",
  SERVICES: "SERVICES",
  Languages: "Languages",
  "languages served/spoken": "languages served/spoken",
  "Services Provided": "Services Provided",
  Requirements: "Requirements",
  "requirements to receive assistance": "requirements to receive assistance",
  "Service Area": "Service Area",
  "service area": "service area",
  OTHERS: "OTHERS",
  "Payments Accepted": "Payments Accepted",
  // New Pantry
  "Please provide a valid state.": "Please provide a valid state.",
  "At least one currency payment need to be filled!":
    "At least one currency payment need to be filled!",
  "You need to choose at least 1 of the filters to be able to submit the form!!":
    "You need to choose at least 1 of the filters to be able to submit the form!!",
  "You need to fill at least one of the following days(hours too)!!":
    "You need to fill at least one of the following days(hours too)!!",
  "Suggest or Create New Resource": "Suggest or Create New Resource",
  FILTERS: "FILTERS",
  "Choose one or more options that best represent this resource.":
    "Choose one or more options that best represent this resource.",
  GENERAL: "GENERAL",
  "Name of Health Resource": "Name of Health Resource",
  Latitude: "Latitude",
  latitude: "latitude",
  Longitude: "Longitude",
  longitude: "longitude",
  Address1: "Address1",
  address1: "address1",
  Address2: "Address2",
  address2: "address2",
  City: "City",
  city: "city",
  State: "State",
  state: "state",
  "ZIP Code": "ZIP Code",
  zipcode: "zipcode",
  CONTACTS: "CONTACTS",
  Phone: "Phone",
  "phone number": "phone number",
  "Contact person": "Contact person",
  "We're updating the Operating Days and Hours format to improve your experience within the App.":
    "We're updating the Operating Days and Hours format to improve your experience within the App.",
  "Please submit suggestions using the fields below.":
    "Please submit suggestions using the fields below.",
  Days: "Days",
  Hours: "Hours",
  NOTES: "NOTES",
  HOURS: "HOURS",
  to: "to",
  "List services offered": "List services offered",
  "Include telemedicine if provided": "Include telemedicine if provided",
  CURRENCY: "CURRENCY",
  "ADDITIONAL INFORMATION": "ADDITIONAL INFORMATION",
  Cancel: "Cancel",
  Create: "Create",
  "Something went wrong, try again Later!":
    "Something went wrong, try again Later!",
  "Your listing on GNR Health Finder was successfully created. Our Admin team will review and push your listing live as soon as possible. Thank you for being a part of the GNR Health Finder network!":
    "Your listing on GNR Health Finder was successfully created. Our Admin team will review and push your listing live as soon as possible. Thank you for being a part of the GNR Health Finder network!",
  "We only allow Newton & Rockdale County valid ZIP Codes":
    "We only allow Newton & Rockdale County County valid ZIP Codes",
  "We only allow Newton & Rockdale County ZIP Codes":
    "We only allow Newton & Rockdale County County ZIP Codes",
  // Popup
  "Please tell us what information you need so we can try to add it to our list.":
    "Please tell us what information you need so we can try to add it to our list.",
  "What are the biggest barriers you have to getting the healthcare services you need? Check all that apply:":
    "What are the biggest barriers you have to getting the healthcare services you need? Check all that apply:",
  cost: "cost",
  transportation: "transportation",
  "finding a provider": "finding a provider",
  "limited service hours": "limited service hours",
  "language barriers": "language barriers",
  other: "other",
  "Did you find what you needed here today?":
    "Did you find what you needed here today?",
  Yes: "Yes",
  No: "No",
  "Remind me later": "Remind me later",
  "Don't show again": "Don't show again",
  Submit: "Submit",
  "Thank you for your help!": "Thank you for your help!",
  "Your feedback has been successfully submitted. Your opinion is important for us!":
    "Your feedback has been successfully submitted. Your opinion is important for us!",
};
