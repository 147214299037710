/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect, useContext } from "react";
import "./Details.css";
// import Logo from '../../images/group-28@2x.png';
import "../../CSS/bootstrap.css";
import {
  getDataPantryDetails,
  useCurrencies,
  useDataPantryDetails,
  useLanguages,
} from "../../services/fetch";
import { dataToPM, StaticImages, weekdays } from "../Home/helpers";
import ReactGa from "react-ga4";
import MultiLingualContent from "../../services/LanguageContext/MultiLingualContent";
// import { consoleFF } from '../helpers';
import {
  CCol,
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
} from "@coreui/react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { LanguageContext } from "../../services/LanguageContext/MultiLingualContext";
import LanguageIcon from "@mui/icons-material/Language";
export default function Details({ match, history }) {
  const pantryId = match.params.id;
  //console.log(match)
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useDataPantryDetails(pantryId, history, []);
  const [currency, setCurrency] = useCurrencies(history, []);
  const [detail, setDetail] = useState([]);
  const [currencies, setCurrencies] = useState("");
  const [address, setAddress] = useState("");
  const [schedule, setSchedule] = useState(false);
  const [days, setDays] = useState({});

  useEffect(() => {
    //ReactGa.initialize('UA-173358873-2')
    // ReactGa.pageview('details/' + pantryId);
    ReactGa.send({ hitType: "pageView", page: "details/" + pantryId });
  }, []);

  const handleHomeClick = () => {
    history.push(`/`);
  };

  // const handleNewPantryClick = (event) => {
  //   event.preventDefault();
  //   history.push('/new');
  // };

  const handleSuggestClick = (event) => {
    event.preventDefault();
    history.push(`/suggest/${pantryId}`);
  };

  const handleNotAClick = (event) => {
    event.preventDefault();
  };

  const detailsAddress = () => {
    let address = [
      data.data.address1 !== undefined ? data.data.address1 : "",
      data.data.address2 !== undefined ? data.data.address2 : "",
      data.data.city !== undefined ? data.data.city : "",
      data.data.state !== undefined ? data.data.state : "",
      data.data.zip_code !== undefined ? data.data.zip_code : "",
    ];
    address = address.join(", ");
    return setAddress(address);
  };

  const detailsSchedule = () => {
    let json;
    try {
      json = JSON.parse(data.data.operating_days);
    } catch (e) {
      return false;
    }
    setDays({
      monday: json.monday.checked,
      tuesday: json.tuesday.checked,
      wednesday: json.wednesday.checked,
      thursday: json.thursday.checked,
      friday: json.friday.checked,
      saturday: json.saturday.checked,
      sunday: json.sunday.checked,
    });
    console.log(json);
    return setSchedule(json);
  };

  const getCurrenciesNamesFromSlugs = (cur) => {
    //console.log(currency)
    //return;
    if (cur === undefined || cur === "" || cur === null) return;

    let currencies = [];
    cur.split(",").map((c) => {
      currencies.push(currency.data.find((o) => o.tla === c).name);
    });
    currencies = currencies.join(" , ");
    setCurrencies(currencies);
  };

  //currencies
  useMemo(() => {
    if (currency.loaded && data.loaded) {
      setCurrency(currency.data);
      setTimeout(() => {
        getCurrenciesNamesFromSlugs(data.data.currency);
      }, 500);
    }
  }, [currency, data]);

  useMemo(() => {
    if (data.loaded) {
      setDetail(data.data);
      detailsAddress();
      detailsSchedule();
    }
  }, [data]);

  const [languageList, setLanguageList] = useLanguages(history, []);
  const [selectLanguage, setSelectLanguage] = useState(
    localStorage.getItem("language")
      ? JSON.parse(localStorage.getItem("language"))
      : "EN"
  );
  const { language, toggleLanguage } = useContext(LanguageContext);

  useMemo(async () => {
    if (data.loaded) {
      await getDataPantryDetails(pantryId, setDetail);
    }
  }, [language]);

  const handleLanguageClick = (event) => {
    setSelectLanguage(
      languageList
        .find((lg) => lg.value === event.target.value)
        .tla.toUpperCase()
    );
    toggleLanguage(
      languageList
        .find((lg) => lg.value === event.target.value)
        .tla.toUpperCase()
    );
    localStorage.setItem(
      "language",
      JSON.stringify(
        languageList
          .find((lg) => lg.value === event.target.value)
          .tla.toUpperCase()
      )
    );
  };
  useMemo(() => {
    if (languageList.loaded) {
      let info = [];
      languageList.data.forEach((language) => {
        info.push({
          value: language.id,
          label: language.name,
          tla: language.tla,
        });
      });

      setLanguageList(info);
      setSelectLanguage(
        info.find((lg) => lg.tla.toUpperCase() === language).tla.toUpperCase()
      );
      localStorage.setItem(
        "language",
        JSON.stringify(
          info.find((lg) => lg.tla.toUpperCase() === language).tla.toUpperCase()
        )
      );
    }
  }, [languageList]);
  return (
    <div>
      <div className="headerBar">
        <div className="headerMiddle"></div>
        <div className="headerMiddleFlexEnd">
          <CDropdown variant="dropdown" autoClose="outside">
            <CDropdownToggle caret={false} className="dropdownStyle">
              <LanguageIcon
                style={{
                  height: "16px",
                  width: "13px",
                  paddingBottom: "3px",
                  marginRight: "3px",
                }}
              />
              {selectLanguage}
            </CDropdownToggle>
            <CDropdownMenu
              style={{
                position: "absolute",
                inset: "0px 0px auto auto",
                transform: "translate(-8px, 30px)",
              }}
            >
              <FormControl>
                <FormLabel
                  style={{
                    fontSize: "14px",
                    fontFamily: "'Roboto'",
                    fontWeight: "700",
                    color: "#373a3c",
                    textAlign: "center",
                  }}
                >
                  <MultiLingualContent
                    contentString={"Please select one language:"}
                  />
                </FormLabel>
                <RadioGroup
                  className="radioGroupStyle"
                  onChange={(e) => handleLanguageClick(e)}
                >
                  {languageList.length &&
                    languageList.map((language) => (
                      <FormControlLabel
                        value={language.value}
                        label={language.label}
                        control={
                          <Radio
                            checked={
                              languageList.find(
                                (lg) => lg.value === language.value
                              ).tla === selectLanguage.toLowerCase()
                                ? true
                                : false
                            }
                          />
                        }
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </CDropdownMenu>
          </CDropdown>
        </div>
      </div>
      <div className="container-fluid provider-container">
        <div className="row">
          <div className="col-sm-0 col-md-1"></div>
          <div className="col-sm-12 col-md-10">
            <CRow className="BackButtonDetails">
              <CCol md={1} onClick={handleHomeClick} className="d-inline-flex ">
                {StaticImages.Back}
              </CCol>
            </CRow>
            <h3 tabIndex="0" aria-label={"Pantry Name: " + detail.name}>
              {detail.name}
            </h3>
            <div className="weekdayChipDiv">
              {Object.keys(weekdays).map((weekday) =>
                days[weekday] ? (
                  <span className="weekdayChip">{weekdays[weekday]}</span>
                ) : null
              )}
            </div>
            <div className="suggestEdit">
              <a
                href=""
                onClick={handleSuggestClick}
                className="suggest-edit btn"
              >
                <MultiLingualContent contentString={"Suggest an Edit"} />
              </a>
            </div>
            <h6 className="boxTitle">
              <MultiLingualContent contentString={"CONTACTS"} />
            </h6>
            <div className="contacts-box">
              <div
                className="contact"
                style={{
                  display: detail.hasOwnProperty("address1") ? "block" : "none",
                }}
              >
                <span className="helper"></span>
                {StaticImages.Address}
                <div className="details">
                  <p>
                    <MultiLingualContent contentString={"Get Directions"} />
                  </p>
                  <a
                    aria-label={"Address: " + address}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"http://maps.google.com/?q=" + address}
                  >
                    <span>{address}</span>
                  </a>
                </div>
              </div>
              <div
                className="contact"
                style={{
                  display: detail.hasOwnProperty("url") ? "block" : "none",
                }}
              >
                <span className="helper"></span>
                {StaticImages.Website}
                <div className="details">
                  <p>
                    <MultiLingualContent contentString={"Website"} />
                  </p>
                  <a
                    aria-label={"Website" + detail.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={detail.url}
                  >
                    {detail.url}
                  </a>
                </div>
              </div>
              <div
                className="contact"
                style={{
                  display: detail.hasOwnProperty("phone_number")
                    ? "block"
                    : "none",
                }}
              >
                <span className="helper"></span>
                {StaticImages.Phone}
                <div className="details">
                  <p>
                    <MultiLingualContent contentString={"Call"} />
                  </p>
                  <a
                    aria-label={"Phone Number: " + detail.phone_number}
                    href={"tel:" + detail.phone_number}
                  >
                    {detail.phone_number}
                  </a>
                </div>
              </div>
              <div
                className="contact"
                style={{
                  display: detail.hasOwnProperty("email") ? "block" : "none",
                }}
              >
                <span className="helper"></span>
                {StaticImages.Email}
                <div className="details">
                  <p>
                    <MultiLingualContent contentString={"Email"} />
                  </p>
                  <a
                    aria-label={"Email: " + detail.email}
                    href={"mailto:" + detail.email}
                  >
                    {detail.email}
                  </a>
                </div>
              </div>
              <div
                className="contact"
                style={{
                  display: detail.hasOwnProperty("contact_person")
                    ? "block"
                    : "none",
                }}
              >
                <span className="helper"></span>
                {StaticImages.Person}
                <div className="details">
                  <p>
                    <MultiLingualContent contentString={"Contact Person"} />
                  </p>
                  <span
                    tabIndex="0"
                    aria-pressed="false"
                    aria-label={"Contact: " + detail.contact_person}
                    className="contactPP"
                  >
                    {detail.contact_person}
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display:
                  !detail.hasOwnProperty("operating_days") &&
                  !detail.hasOwnProperty("operating_hours")
                    ? "none"
                    : "block",
              }}
            >
              <h6 className="boxTitle">
                <MultiLingualContent contentString={"OPERATING DAYS / HOURS"} />
              </h6>
              <div className="operating-box">
                <div className="service days-box">
                  <div
                    className="days-icons"
                    style={{ display: schedule !== false ? "flex" : "none" }}
                  >
                    <span className={`day ${days.monday ? "day-open" : ""}`}>
                      M
                    </span>
                    <span className={`day ${days.tuesday ? "day-open" : ""}`}>
                      T
                    </span>
                    <span className={`day ${days.wednesday ? "day-open" : ""}`}>
                      W
                    </span>
                    <span className={`day ${days.thursday ? "day-open" : ""}`}>
                      T
                    </span>
                    <span className={`day ${days.friday ? "day-open" : ""}`}>
                      F
                    </span>
                    <span className={`day ${days.saturday ? "day-open" : ""}`}>
                      S
                    </span>
                    <span className={`day ${days.sunday ? "day-open" : ""}`}>
                      S
                    </span>
                  </div>
                  <div
                    className="schedule-box"
                    style={{ display: schedule === false ? "block" : "none" }}
                  >
                    <div
                      aria-label={
                        "Operating Days/Hours: " +
                        detail.operating_days +
                        " " +
                        detail.operating_hours
                      }
                      className="schedule-details services-details "
                      tabIndex="0"
                      aria-pressed="false"
                    >
                      <span className="service-description-day">
                        {detail.operating_days}
                      </span>
                      <span className="service-description-hour">
                        {detail.operating_hours}
                      </span>
                    </div>
                  </div>
                  <div
                    className="schedule-box"
                    // style={{ display: schedule !== false ? 'grid' : 'none' }}
                  >
                    <div
                      className="schedule-details services-details"
                      style={{ display: days.monday ? "block" : "none" }}
                    >
                      <a
                        className="NotA"
                        onClick={handleNotAClick}
                        aria-label="Monday Operating Day"
                        href=""
                      >
                        <span className="service-description-day">
                          <MultiLingualContent contentString={"Monday"} />
                        </span>
                        {schedule.hasOwnProperty("monday") &&
                        schedule.monday.notes ? (
                          <span className="service-description-note">
                            {schedule.monday.notes}
                          </span>
                        ) : null}
                        <span className="service-description-hour">
                          {schedule.hasOwnProperty("monday") &&
                          schedule.monday.hours !== false
                            ? schedule.monday.hours.map(
                                (e) =>
                                  `${dataToPM(e.hoursFrom) || ""} - ${dataToPM(
                                    e.hoursTo
                                  )}` || ""
                              )
                            : ""}
                        </span>
                        {/* <br />
                        <span className="service-description-note">
                          {schedule.hasOwnProperty('monday')
                            ? schedule.monday.notes
                            : ''}
                        </span> */}
                        <span className="helper"></span>
                      </a>
                    </div>
                    <div
                      className="schedule-details services-details"
                      style={{ display: days.tuesday ? "block" : "none" }}
                    >
                      <a
                        className="NotA"
                        onClick={handleNotAClick}
                        aria-label="Tuesday Operating Day"
                        href=""
                      >
                        <span className="service-description-day">
                          <MultiLingualContent contentString={"Tuesday"} />
                        </span>
                        {schedule.hasOwnProperty("tuesday") &&
                        schedule.tuesday.notes ? (
                          <span className="service-description-note">
                            {schedule.tuesday.notes}
                          </span>
                        ) : null}
                        <span className="service-description-hour">
                          {schedule.hasOwnProperty("tuesday") &&
                          schedule.tuesday.hours !== false
                            ? schedule.tuesday.hours.map(
                                (e) =>
                                  `${dataToPM(e.hoursFrom) || ""} - ${dataToPM(
                                    e.hoursTo
                                  )}`
                              )
                            : ""}
                        </span>
                        {/* <br />
                        <span className="service-description-note">
                          {schedule.hasOwnProperty('tuesday')
                            ? schedule.tuesday.notes
                            : ''}
                        </span> */}
                        <span className="helper"></span>
                      </a>
                    </div>
                    <div
                      className="schedule-details services-details"
                      style={{ display: days.wednesday ? "block" : "none" }}
                    >
                      <a
                        className="NotA"
                        onClick={handleNotAClick}
                        aria-label="Wednesday Operating Day"
                        href=""
                      >
                        <span className="service-description-day">
                          <MultiLingualContent contentString={"Wednesday"} />
                        </span>
                        {schedule.hasOwnProperty("wednesday") &&
                        schedule.wednesday.notes ? (
                          <span className="service-description-note">
                            {schedule.wednesday.notes}
                          </span>
                        ) : null}
                        <span className="service-description-hour">
                          {schedule.hasOwnProperty("wednesday") &&
                          schedule.wednesday.hours !== false
                            ? schedule.wednesday.hours.map(
                                (e) =>
                                  `${dataToPM(e.hoursFrom) || ""} - ${dataToPM(
                                    e.hoursTo
                                  )}`
                              )
                            : ""}
                        </span>
                        {/* <br />
                        <span className="service-description-note">
                          {schedule.hasOwnProperty('wednesday')
                            ? schedule.wednesday.notes
                            : ''}
                        </span> */}
                        <span className="helper"></span>
                      </a>
                    </div>
                    <div
                      className="schedule-details services-details"
                      style={{ display: days.thursday ? "block" : "none" }}
                    >
                      <a
                        className="NotA"
                        onClick={handleNotAClick}
                        aria-label="Thursday Operating Day"
                        href=""
                      >
                        <span className="service-description-day">
                          <MultiLingualContent contentString={"Thursday"} />
                        </span>
                        {schedule.hasOwnProperty("thursday") &&
                        schedule.thursday.notes ? (
                          <span className="service-description-note">
                            {schedule.thursday.notes}
                          </span>
                        ) : null}
                        <span className="service-description-hour">
                          {schedule.hasOwnProperty("thursday") &&
                          schedule.thursday.hours !== false
                            ? schedule.thursday.hours.map(
                                (e) =>
                                  `${dataToPM(e.hoursFrom) || ""} - ${dataToPM(
                                    e.hoursTo
                                  )}`
                              )
                            : ""}
                        </span>
                        {/* <br />
                        <span className="service-description-note">
                          {schedule.hasOwnProperty('thursday')
                            ? schedule.thursday.notes
                            : ''}
                        </span> */}
                        <span className="helper"></span>
                      </a>
                    </div>
                    <div
                      className="schedule-details services-details"
                      style={{ display: days.friday ? "block" : "none" }}
                    >
                      <a
                        className="NotA"
                        onClick={handleNotAClick}
                        aria-label="Friday Operating Day"
                        href=""
                      >
                        <span className="service-description-day">
                          <MultiLingualContent contentString={"Friday"} />
                        </span>
                        {schedule.hasOwnProperty("friday") &&
                        schedule.friday.notes ? (
                          <span className="service-description-note">
                            {schedule.friday.notes}
                          </span>
                        ) : null}
                        <span className="service-description-hour">
                          {schedule.hasOwnProperty("friday") &&
                          schedule.friday.hours !== false
                            ? schedule.friday.hours.map(
                                (e) =>
                                  `${dataToPM(e.hoursFrom) || ""} - ${dataToPM(
                                    e.hoursTo
                                  )}`
                              )
                            : ""}
                        </span>
                        {/* <br />
                        <span className="service-description-note">
                          {schedule.hasOwnProperty('friday')
                            ? schedule.friday.notes
                            : ''}
                        </span> */}
                        <span className="helper"></span>
                      </a>
                    </div>
                    <div
                      className="schedule-details services-details"
                      style={{ display: days.saturday ? "block" : "none" }}
                    >
                      <a
                        className="NotA"
                        onClick={handleNotAClick}
                        aria-label="Saturday Operating Day"
                        href=""
                      >
                        <span className="service-description-day">
                          <MultiLingualContent contentString={"Saturday"} />
                        </span>
                        {schedule.hasOwnProperty("saturday") &&
                        schedule.saturday.notes ? (
                          <span className="service-description-note">
                            {schedule.saturday.notes}
                          </span>
                        ) : null}
                        <span className="service-description-hour">
                          {schedule.hasOwnProperty("saturday") &&
                          schedule.saturday.hours !== false
                            ? schedule.saturday.hours.map(
                                (e) =>
                                  `${dataToPM(e.hoursFrom) || ""} - ${dataToPM(
                                    e.hoursTo
                                  )}`
                              )
                            : ""}
                        </span>
                        {/* <br />
                        <span className="service-description-note">
                          {schedule.hasOwnProperty('saturday')
                            ? schedule.saturday.notes
                            : ''}
                        </span> */}
                        <span className="helper"></span>
                      </a>
                    </div>
                    <div
                      className="schedule-details services-details"
                      style={{ display: days.sunday ? "block" : "none" }}
                    >
                      <a
                        className="NotA"
                        onClick={handleNotAClick}
                        aria-label="Sunday Operating Day"
                        href=""
                      >
                        <span className="service-description-day">
                          <MultiLingualContent contentString={"Sunday"} />
                        </span>
                        {schedule.hasOwnProperty("sunday") &&
                        schedule.sunday.notes ? (
                          <span className="service-description-note">
                            {schedule.sunday.notes}
                          </span>
                        ) : null}
                        <span className="service-description-hour">
                          {schedule.hasOwnProperty("sunday") &&
                          schedule.sunday.hours !== false
                            ? schedule.sunday.hours.map(
                                (e) =>
                                  `${dataToPM(e.hoursFrom) || ""} - ${dataToPM(
                                    e.hoursTo
                                  )}`
                              )
                            : ""}
                        </span>
                        {/* <br />
                        <span className="service-description-note">
                          {schedule.hasOwnProperty('sunday')
                            ? schedule.sunday.notes
                            : ''}
                        </span> */}
                        <span className="helper"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display:
                  !detail.hasOwnProperty("services1") &&
                  !detail.hasOwnProperty("languages") &&
                  !detail.hasOwnProperty("requirements") &&
                  !detail.hasOwnProperty("serviceArea")
                    ? "none"
                    : "block",
              }}
            >
              <h6 className="boxTitle">
                <MultiLingualContent contentString={"SERVICES"} />
              </h6>
              <div className="services-box">
                <div className="service operations-box">
                  {detail.languages ? (
                    <div
                      className="operating-details-box"
                      style={{
                        display: detail.hasOwnProperty("languages")
                          ? "block"
                          : "none",
                      }}
                    >
                      <h6 className="services-subtitle">
                        <MultiLingualContent contentString={"Languages"} />
                      </h6>
                      <div className="services-details">
                        <span
                          tabIndex="0"
                          aria-pressed="false"
                          aria-label={"Languages: " + detail.languages}
                        >
                          {detail.languages}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {detail.services1 ? (
                    <div
                      className="operating-details-box"
                      style={{
                        display: detail.hasOwnProperty("services1")
                          ? "block"
                          : "none",
                      }}
                    >
                      <h6 className="services-subtitle">
                        <MultiLingualContent
                          contentString={"Services Provided"}
                        />
                      </h6>
                      <div className="services-details">
                        <a
                          className="NotA"
                          aria-label={"Services: " + detail.services1}
                          href=""
                          onClick={handleNotAClick}
                        >
                          <span>{detail.services1}</span>
                        </a>
                      </div>
                    </div>
                  ) : null}
                  {detail.requirements ? (
                    <div
                      className="operating-details-box"
                      style={{
                        display: detail.hasOwnProperty("requirements")
                          ? "block"
                          : "none",
                      }}
                    >
                      <h6 className="services-subtitle">
                        <MultiLingualContent contentString={"Requirements"} />
                      </h6>
                      <div className="services-details">
                        <span
                          tabIndex="0"
                          aria-pressed="false"
                          aria-label={"Requirements: " + detail.requirements}
                        >
                          {detail.requirements}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  {detail.serviceArea ? (
                    <div
                      className="operating-details-box"
                      style={{
                        display:
                          detail.hasOwnProperty("serviceArea") &&
                          detail.serviceArea !== ""
                            ? "block"
                            : "none",
                      }}
                    >
                      <h6 className="services-subtitle">
                        <MultiLingualContent contentString={"Service Area"} />
                      </h6>
                      <div className="services-details">
                        <span
                          tabIndex="0"
                          aria-pressed="false"
                          aria-label={"Service Area: " + detail.serviceArea}
                        >
                          {detail.serviceArea}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <h6
              className="boxTitle"
              style={{
                display: detail.hasOwnProperty("currency") ? "block" : "none",
              }}
            >
              <MultiLingualContent contentString={"OTHERS"} />
            </h6>
            <div className="services-box">
              <div
                className="service operations-box othersCurrency"
                style={{
                  display: detail.hasOwnProperty("currency") ? "block" : "none",
                }}
              >
                <div className="operating-details-box">
                  <h6 className="services-subtitle">
                    <MultiLingualContent contentString={"Payments Accepted"} />
                  </h6>
                  <div className="services-details">
                    <span
                      tabIndex="0"
                      aria-pressed="false"
                      aria-label={"Currency: " + currencies}
                    >
                      {currencies}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-0 col-md-1"></div>
        </div>
      </div>
    </div>
  );
}
