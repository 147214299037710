/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useMemo, useState } from 'react';
// import Logo from '../../images/group-28@2x.png';
import { StaticImages } from '../Home/helpers';
import { prepareSubmit, Value } from './sendPrepare.js';
import './NewPantry.css';
import ReactDOM from 'react-dom';
import {
  getDataPantryDetails,
  useDataPantryDetails,
  useFilters,
  useLanguages,
} from '../../services/fetch';
import { fillFields, prepareDays } from './sendPrepare';
import MultiLingualContent from '../../services/LanguageContext/MultiLingualContent';
// import { consoleFF } from '../helpers';
import {
  CButton,
  CCol,
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CRow,
} from '@coreui/react';
import LanguageIcon from '@mui/icons-material/Language';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { LanguageContext } from '../../services/LanguageContext/MultiLingualContext';

export default function NewPantry({ match, history }) {
  //if its defined its a suggest an edit otherwise its a new suggest
  const pantryId = match.params.id;
  const [filters, setFilters] = useFilters(history, []);
  const [data, setData] = useDataPantryDetails(pantryId, history, []);
  const [schedule, setSchedule] = useState(false);
  const [suggest, setSuggest] = useState({});

  const stateRegex = new RegExp(
    /(AL|AK|AZ|AR|CA|CO|CT|DE|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY)$/
  );

  const detailsSchedule = () => {
    let json;
    try {
      json = JSON.parse(data.data.operating_days);
    } catch (e) {
      return false;
    }
    return setSchedule(json);
  };

  const [languageList, setLanguageList] = useLanguages(history, []);
  const { language, toggleLanguage } = useContext(LanguageContext);
  const [selectLanguage, setSelectLanguage] = useState(
    localStorage.getItem('language')
      ? JSON.parse(localStorage.getItem('language'))
      : 'EN'
  );

  useMemo(async () => {
    if (data.loaded) {
      await getDataPantryDetails(pantryId, setSuggest, fillFields);
    }
  }, [language]);

  const handleLanguageClick = (event) => {
    setSelectLanguage(
      languageList
        .find((lg) => lg.value === event.target.value)
        .tla.toUpperCase()
    );
    toggleLanguage(
      languageList
        .find((lg) => lg.value === event.target.value)
        .tla.toUpperCase()
    );
    localStorage.setItem(
      'language',
      JSON.stringify(
        languageList
          .find((lg) => lg.value === event.target.value)
          .tla.toUpperCase()
      )
    );
  };
  useMemo(() => {
    if (languageList.loaded) {
      let info = [];
      languageList.data.forEach((language) => {
        info.push({
          value: language.id,
          label: language.name,
          tla: language.tla,
        });
      });

      setLanguageList(info);
      setSelectLanguage(
        info.find((lg) => lg.tla.toUpperCase() === language).tla.toUpperCase()
      );
      // setSelectLanguage(
      //   info.find((lg) => +lg.value === selectLanguage).tla.toUpperCase()
      // );
      localStorage.setItem(
        'language',
        JSON.stringify(
          info.find((lg) => lg.tla.toUpperCase() === language).tla.toUpperCase()
        )
      );
    }
  }, [languageList]);

  useMemo(() => {
    if (data.loaded) {
      setTimeout(() => {
        if (data.data !== undefined) {
          // setFilters(filters.data);
          setSuggest(data.data);
          fillFields(data.data);
        }
        detailsSchedule();
      }, 500);
    }
  }, [data]);

  useMemo(() => {
    if (filters.loaded) {
      setFilters(filters.data);
    }
  }, [filters]);

  const [days, setDays] = useState({
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: true,
    Sunday: true,
  });

  const handleHomeClick = () => {
    history.push(`/`);
  };

  // const handleNewPantryClick = () => {
  //   history.push('/new');
  // };

  const handleDayClick = (id) => {
    //ReactDOM.findDOMNode(document.getElementById(id)).style.opacity = 0.4
    let day = days;
    if (day[id]) {
      day[id] = false;
    } else {
      day[id] = true;
    }
    setDays(day);
  };

  const addInputCurrency = (name, id) => {
    return (
      <label className="-container">
        {name}
        <input className="currenciesCheckBoxs" id={id} type="checkbox" />
        <span
          tabIndex="0"
          role="button"
          aria-pressed="false"
          className="checkmark"
        ></span>
      </label>
    );
  };

  // const overrideTranslations = (event) => {
  //   let override = true;
  //   handleSubmit(event, override);
  // };

  const handleSubmit = (event, override = false) => {
    event.preventDefault();
    if (Value('name-field') === '')
      return ReactDOM.findDOMNode(
        document.getElementById('name-field')
      ).focus();
    if (Value('address1-field') === '')
      return ReactDOM.findDOMNode(
        document.getElementById('address1-field')
      ).focus();
    if (Value('city-field') === '')
      return ReactDOM.findDOMNode(
        document.getElementById('city-field')
      ).focus();
    if (Value('phone-field') === '')
      return ReactDOM.findDOMNode(
        document.getElementById('phone-field')
      ).focus();
    if (Value('website-field') === '')
      return ReactDOM.findDOMNode(
        document.getElementById('website-field')
      ).focus();
    if (Value('email-field') === '')
      return ReactDOM.findDOMNode(
        document.getElementById('email-field')
      ).focus();
    if (Value('contact-field') === '')
      return ReactDOM.findDOMNode(
        document.getElementById('contact-field')
      ).focus();
    if (Value('state-field').search(stateRegex) === -1) {
      ReactDOM.findDOMNode(document.getElementById('state-field')).focus();
      return window.alert(
        <MultiLingualContent contentString={'Please provide a valid state.'} />
      );
    }

    if (
      [...document.querySelectorAll('.currenciesCheckBoxs')]
        .map((e) => e.checked)
        .find((e) => e === true) !== true
    )
      return window.alert(
        <MultiLingualContent
          contentString={'At least one currency payment need to be filled!'}
        />
      );

    let select = 0;
    let ff = document.getElementsByClassName('filterChoice');
    for (let i = 0; i < ff.length; i++) if (ff[i].checked) select++;

    if (select === 0) {
      alert(
        <MultiLingualContent
          contentString={
            'You need to choose at least 1 of the filters to be able to submit the form!!'
          }
        />
      );
      return ff[0].focus();
    }

    checkDaysHours().then((res) => {
      if (res === false) {
        alert(
          <MultiLingualContent
            contentString={
              'You need to fill at least one of the following days(hours too)!!'
            }
          />
        );
        return document.getElementById('monday').focus();
      } else {
        let language = localStorage.getItem('language')
          ? JSON.parse(localStorage.getItem('language')).toLowerCase()
          : 'en';

        let language_id = +languageList.find(
          (lg) => lg.tla === language.toLowerCase()
        ).value;
        prepareSubmit(history, pantryId, language_id, override);
      }
    });
    //console.log(ReactDOM.findDOMNode(document.getElementById("name-field")).value)
  };

  /**
   * IF doesnt have any day of the week(hour) filled alert the user
   **/
  async function checkDaysHours() {
    let array = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];
    let res = prepareDays(true);
    let day_select = 0;
    let hour_select = 0;
    array.forEach((day) => {
      if (res[day].checked === true || res[day].checked === 'true')
        day_select++;
      if (
        res[day].hours[0] !== undefined &&
        (res[day].hours[0].hasOwnProperty('hoursFrom') ||
          res[day].hours[0].hasOwnProperty('hoursTo'))
      )
        hour_select++;
    });
    if (day_select !== 0 && hour_select !== 0) return true;

    return false;
  }

  // function _handleKeyPressBackButton(e) {
  //   if (e.key === 'Enter') {
  //     handleHomeClick();
  //   }
  // }
  // console.log(data.data);
  return (
    <div>
      <div className="headerBar">
        <div className="headerLeft">
          {/*<img tabIndex="0" role="button" aria-pressed="false" aria-label="back button logo" className="Logo" onKeyPress={_handleKeyPressBackButton} onClick={handleHomeClick} src={Logo} alt="logo"/>*/}
        </div>
        <div className="headerMiddleFlexEnd">
          <CDropdown variant="dropdown" autoClose="outside">
            <CDropdownToggle caret={false} className="dropdownStyle">
              <LanguageIcon
                style={{
                  height: '16px',
                  width: '13px',
                  paddingBottom: '3px',
                  marginRight: '3px',
                }}
              />
              {selectLanguage}
            </CDropdownToggle>
            <CDropdownMenu
              style={{
                position: 'absolute',
                inset: '0px 0px auto auto',
                transform: 'translate(-8px, 30px)',
              }}
              // className="dropdownMenuStyle"
            >
              <FormControl>
                <FormLabel
                  style={{
                    fontSize: '14px',
                    fontFamily: "'Roboto'",
                    fontWeight: '700',
                    color: '#373a3c',
                    textAlign: 'center',
                  }}
                >
                  <MultiLingualContent
                    contentString={'Please select one language:'}
                  />
                </FormLabel>
                <RadioGroup
                  className="radioGroupStyle"
                  onChange={(e) => handleLanguageClick(e)}
                >
                  {languageList.length &&
                    languageList.map((language) => (
                      <FormControlLabel
                        value={language.value}
                        label={language.label}
                        control={
                          <Radio
                            checked={
                              languageList.find(
                                (lg) => lg.value === language.value
                              ).tla === selectLanguage.toLowerCase()
                                ? true
                                : false
                            }
                            //color="#00A0AF"
                          />
                        }
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </CDropdownMenu>
          </CDropdown>
        </div>
      </div>
      <div className="addPantry">
        <CRow className="BackButtonNew">
          <CCol md={1} onClick={handleHomeClick} className="d-inline-flex ">
            {StaticImages.Back}
          </CCol>
          {/* {pantryId && (
            <CCol md={1} className="d-flex flex-row-reverse ">
              <CButton>Override All Translations</CButton>
            </CCol>
          )} */}
        </CRow>
        {/* <div
          tabIndex="0"
          role="button"
          aria-pressed="false"
          aria-label="back button logo"
          className="BackButtonNew"
          onClick={handleHomeClick}
        >
          {StaticImages.Back}
        </div> */}
        <div className="mainTitle">
          {pantryId != null ? (
            <MultiLingualContent
              contentString={'Suggest or Create New Resource'}
            />
          ) : (
            <MultiLingualContent
              contentString={'Suggest or Create New Resource'}
            />
          )}
        </div>
        <form onSubmit={() => handleSubmit(this)}>
          <div className="generalDiv">
            <MultiLingualContent contentString={'FILTERS'} />
          </div>
          <div className="row--">
            <div className="rowTitle">
              <MultiLingualContent
                contentString={
                  'Choose one or more options that best represent this resource.'
                }
              />
              <span className="newRequired">*</span>
            </div>
            <div className="currencyTypes">
              {filters.length &&
                filters.map((f) => {
                  return (
                    <label key={f.id} className="-container">
                      {MultiLingualContent({ contentString: f.name })}
                      {/* {f.name} */}
                      <input
                        className="filterChoice"
                        data-id={f.id}
                        type="checkbox"
                      />
                      <span
                        tabIndex="0"
                        role="button"
                        aria-pressed="false"
                        className="checkmark"
                      ></span>
                    </label>
                  );
                })}
            </div>
          </div>
          <div className="generalDiv">
            <MultiLingualContent contentString={'GENERAL'} />
          </div>
          <div className="row-- row--1">
            <div className="rowTitle">
              <MultiLingualContent contentString={'Name of Health Resource'} />
              <span className="newRequired">*</span>
            </div>
            <input
              required
              type="text"
              placeholder={MultiLingualContent({
                contentString: 'Name of Health Resource',
              })}
              id="name-field"
            />
          </div>
          <div className="row-- row--2">
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'Latitude'} />
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'latitude',
                })}
                id="latitude"
              />
            </div>
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'Longitude'} />
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'longitude',
                })}
                id="longitude"
              />
            </div>
          </div>
          <div className="row-- row--2">
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'Address1'} />
                <span className="newRequired">*</span>
              </div>
              <input
                required
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'address1',
                })}
                id="address1-field"
              />
            </div>
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'Address2'} />
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'address2',
                })}
                id="address2-field"
              />
            </div>
          </div>
          <div className="row-- row--1">
            <div className="rowTitle">
              <MultiLingualContent contentString={'City'} />
              <span className="newRequired">*</span>
            </div>
            <input
              required
              type="text"
              placeholder={MultiLingualContent({
                contentString: 'city',
              })}
              id="city-field"
            />
          </div>
          <div className="row-- row--2">
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'State'} />
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'state',
                })}
                id="state-field"
              />
            </div>
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'ZIP Code'} />
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'zipcode',
                })}
                id="zip-field"
              />
            </div>
          </div>
          <div className="generalDiv">
            <MultiLingualContent contentString={'CONTACTS'} />
          </div>
          <div className="row-- row--2">
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'Phone'} />
                <span className="newRequired">*</span>
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'phone number',
                })}
                id="phone-field"
              />
            </div>
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'Website'} />
                <span className="newRequired">*</span>
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'website url',
                })}
                id="website-field"
              />
            </div>
          </div>
          <div className="row-- row--2">
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'Email'} />
                <span className="newRequired">*</span>
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'email address',
                })}
                id="email-field"
              />
            </div>
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'Contact Person'} />
                <span className="newRequired">*</span>
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'contact person name',
                })}
                id="contact-field"
              />
            </div>
          </div>
          <div className="generalDiv">
            <MultiLingualContent contentString={'OPERATING DAYS / HOURS'} />
            <span className="newRequired">*</span>
          </div>
          {schedule === false && selectLanguage === 'EN' && (
            <div className="old-operatingDH">
              <div className="note">
                <p>
                  <MultiLingualContent
                    contentString={
                      "We're updating the Operating Days and Hours format to improve your experience within the App."
                    }
                  />
                </p>
                <p>
                  <MultiLingualContent
                    contentString={
                      'Please submit suggestions using the fields below.'
                    }
                  />
                </p>
              </div>
              <div className="old-items">
                <p>
                  <span>
                    <MultiLingualContent contentString={'Days'} />
                  </span>
                  <label id="operatingDays"></label>
                </p>
                <p>
                  <span>
                    <MultiLingualContent contentString={'Hours'} />
                  </span>
                  <label id="operatingHours"></label>
                </p>
              </div>
            </div>
          )}
          {selectLanguage !== 'EN' && (
            <div className="old-operatingDH">
              <div className="note">
                <p>
                  <MultiLingualContent
                    contentString={
                      'For the time being, changes to operating days and hours are only available in the english page.'
                    }
                  />
                </p>
                <p>
                  <MultiLingualContent
                    contentString={
                      'We apologize for any inconvenience this may have caused.'
                    }
                  />
                </p>
              </div>
            </div>
          )}
          <div className="row-- operatingDH">
            <div style={{ opacity: days.Monday ? 1 : 0.4 }} id="weekMonday">
              <div className="weekDay">
                <label className="-container">
                  <MultiLingualContent contentString={'Monday'} />

                  <input
                    id="monday"
                    onClick={() => handleDayClick('Monday')}
                    type="checkbox"
                    value="Monday"
                    disabled={language !== 'EN'}
                  />
                  <span
                    tabIndex="0"
                    role="button"
                    aria-pressed="false"
                    className="checkmark"
                  ></span>
                </label>
              </div>
              <div>
                <div className="rowTitle">
                  <MultiLingualContent contentString={'NOTES'} />
                </div>
                <input
                  id="mondayNotes"
                  type="text"
                  placeholder={MultiLingualContent({
                    contentString: 'e.g. 1st and 3rd every month',
                  })}
                  size="25"
                />
              </div>
              <div className="dayHours">
                <div className="rowTitle">
                  <MultiLingualContent contentString={'HOURS'} />
                </div>
                <input
                  id="mondayFrom"
                  type="time"
                  readOnly={language !== 'EN'}
                />
                <div className="dayTo">
                  <MultiLingualContent contentString={'to'} />
                </div>
                <input id="mondayTo" type="time" readOnly={language !== 'EN'} />
              </div>
            </div>
            <div style={{ opacity: days.Tuesday ? 1 : 0.4 }} id="weekTuesday">
              <div className="weekDay">
                <label className="-container">
                  <MultiLingualContent contentString={'Tuesday'} />

                  <input
                    id="tuesday"
                    type="checkbox"
                    value="Tuesday"
                    disabled={language !== 'EN'}
                  />
                  <span
                    tabIndex="0"
                    role="button"
                    aria-pressed="false"
                    className="checkmark"
                  ></span>
                </label>
              </div>
              <div>
                <div className="rowTitle">
                  <MultiLingualContent contentString={'NOTES'} />
                </div>
                <input
                  id="tuesdayNotes"
                  type="text"
                  placeholder={MultiLingualContent({
                    contentString: 'e.g. 1st and 3rd every month',
                  })}
                  size="25"
                />
              </div>
              <div className="dayHours">
                <div className="rowTitle">
                  <MultiLingualContent contentString={'HOURS'} />
                </div>
                <input
                  id="tuesdayFrom"
                  type="time"
                  readOnly={language !== 'EN'}
                />
                <div className="dayTo">
                  <MultiLingualContent contentString={'to'} />
                </div>
                <input
                  id="tuesdayTo"
                  type="time"
                  readOnly={language !== 'EN'}
                />
              </div>
            </div>
            <div
              style={{ opacity: days.Wednesday ? 1 : 0.4 }}
              id="weekWednesday"
            >
              <div className="weekDay">
                <label className="-container">
                  <MultiLingualContent contentString={'Wednesday'} />

                  <input
                    id="wednesday"
                    type="checkbox"
                    value="Wednesday"
                    disabled={language !== 'EN'}
                  />
                  <span
                    tabIndex="0"
                    role="button"
                    aria-pressed="false"
                    className="checkmark"
                  ></span>
                </label>
              </div>
              <div>
                <div className="rowTitle">
                  <MultiLingualContent contentString={'NOTES'} />
                </div>
                <input
                  id="wednesdayNotes"
                  type="text"
                  placeholder={MultiLingualContent({
                    contentString: 'e.g. 1st and 3rd every month',
                  })}
                  size="25"
                />
              </div>
              <div className="dayHours">
                <div className="rowTitle">
                  <MultiLingualContent contentString={'HOURS'} />
                </div>
                <input
                  id="wednesdayFrom"
                  type="time"
                  readOnly={language !== 'EN'}
                />
                <div className="dayTo">
                  <MultiLingualContent contentString={'to'} />
                </div>
                <input
                  id="wednesdayTo"
                  type="time"
                  readOnly={language !== 'EN'}
                />
              </div>
            </div>
            <div style={{ opacity: days.Thursday ? 1 : 0.4 }} id="weekThursday">
              <div className="weekDay">
                <label className="-container">
                  <MultiLingualContent contentString={'Thursday'} />

                  <input
                    id="thursday"
                    type="checkbox"
                    value="Thursday"
                    disabled={language !== 'EN'}
                  />
                  <span
                    tabIndex="0"
                    role="button"
                    aria-pressed="false"
                    className="checkmark"
                  ></span>
                </label>
              </div>
              <div>
                <div className="rowTitle">
                  <MultiLingualContent contentString={'NOTES'} />
                </div>
                <input
                  id="thursdayNotes"
                  type="text"
                  placeholder={MultiLingualContent({
                    contentString: 'e.g. 1st and 3rd every month',
                  })}
                  size="25"
                />
              </div>
              <div className="dayHours">
                <div className="rowTitle">
                  <MultiLingualContent contentString={'HOURS'} />
                </div>
                <input
                  id="thursdayFrom"
                  type="time"
                  readOnly={language !== 'EN'}
                />
                <div className="dayTo">
                  <MultiLingualContent contentString={'to'} />
                </div>
                <input
                  id="thursdayTo"
                  type="time"
                  readOnly={language !== 'EN'}
                />
              </div>
            </div>
            <div style={{ opacity: days.Friday ? 1 : 0.4 }} id="weekFriday">
              <div className="weekDay">
                <label className="-container">
                  <MultiLingualContent contentString={'Friday'} />

                  <input
                    id="friday"
                    type="checkbox"
                    value="Friday"
                    disabled={language !== 'EN'}
                  />
                  <span
                    tabIndex="0"
                    role="button"
                    aria-pressed="false"
                    className="checkmark"
                  ></span>
                </label>
              </div>
              <div>
                <div className="rowTitle">
                  <MultiLingualContent contentString={'NOTES'} />
                </div>
                <input
                  id="fridayNotes"
                  type="text"
                  placeholder={MultiLingualContent({
                    contentString: 'e.g. 1st and 3rd every month',
                  })}
                  size="25"
                />
              </div>
              <div className="dayHours">
                <div className="rowTitle">
                  <MultiLingualContent contentString={'HOURS'} />
                </div>
                <input
                  id="fridayFrom"
                  type="time"
                  readOnly={language !== 'EN'}
                />
                <div className="dayTo">
                  <MultiLingualContent contentString={'to'} />
                </div>
                <input id="fridayTo" type="time" readOnly={language !== 'EN'} />
              </div>
            </div>
            <div style={{ opacity: days.Saturday ? 1 : 0.4 }} id="weekSaturday">
              <div className="weekDay">
                <label className="-container">
                  <MultiLingualContent contentString={'Saturday'} />

                  <input
                    id="saturday"
                    type="checkbox"
                    value="Saturday"
                    disabled={language !== 'EN'}
                  />
                  <span
                    tabIndex="0"
                    role="button"
                    aria-pressed="false"
                    className="checkmark"
                  ></span>
                </label>
              </div>
              <div>
                <div className="rowTitle">
                  <MultiLingualContent contentString={'NOTES'} />
                </div>
                <input
                  id="saturdayNotes"
                  type="text"
                  placeholder={MultiLingualContent({
                    contentString: 'e.g. 1st and 3rd every month',
                  })}
                  size="25"
                />
              </div>
              <div className="dayHours">
                <div className="rowTitle">
                  <MultiLingualContent contentString={'HOURS'} />
                </div>
                <input
                  id="saturdayFrom"
                  type="time"
                  readOnly={language !== 'EN'}
                />
                <div className="dayTo">
                  <MultiLingualContent contentString={'to'} />
                </div>
                <input
                  id="saturdayTo"
                  type="time"
                  readOnly={language !== 'EN'}
                />
              </div>
            </div>
            <div style={{ opacity: days.Sunday ? 1 : 0.4 }} id="weekSunday">
              <div className="weekDay">
                <label className="-container">
                  <MultiLingualContent contentString={'Sunday'} />

                  <input
                    id="sunday"
                    type="checkbox"
                    value="Sunday"
                    disabled={language !== 'EN'}
                  />
                  <span
                    tabIndex="0"
                    role="button"
                    aria-pressed="false"
                    className="checkmark"
                  ></span>
                </label>
              </div>
              <div>
                <div className="rowTitle">
                  <MultiLingualContent contentString={'NOTES'} />
                </div>
                <input
                  id="sundayNotes"
                  type="text"
                  placeholder={MultiLingualContent({
                    contentString: 'e.g. 1st and 3rd every month',
                  })}
                  size="25"
                />
              </div>
              <div className="dayHours">
                <div className="rowTitle">
                  <MultiLingualContent contentString={'HOURS'} />
                </div>
                <input
                  id="sundayFrom"
                  type="time"
                  readOnly={language !== 'EN'}
                />
                <div className="dayTo">
                  <MultiLingualContent contentString={'to'} />
                </div>
                <input id="sundayTo" type="time" readOnly={language !== 'EN'} />
              </div>
            </div>
          </div>
          <div className="row-- row--2">
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'Service Area'} />
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'service area',
                })}
                id="service-field"
              />
            </div>
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'Languages'} />
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'languages served/spoken',
                })}
                id="languages-field"
              />
            </div>
          </div>
          <div className="row-- row--2">
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'List services offered'} />
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'Include telemedicine if provided',
                })}
                id="services-field"
              />
            </div>
            <div>
              <div className="rowTitle">
                <MultiLingualContent contentString={'Requirements'} />
              </div>
              <input
                type="text"
                placeholder={MultiLingualContent({
                  contentString: 'requirements to receive assistance',
                })}
                id="requirements-field"
              />
            </div>
          </div>
          {/*<div className="generalDiv">PORTALS</div>
                    <div className="row--">
                        <div className="rowTitle">Should show on the following portals:</div>
                        <input type="text" placeholder="requirements to receive assistance"
                               id="portals"/>
                    </div>
                    <div className="generalDiv">TAGS</div>
                    <div className="row--">
                        <div className="rowTitle">Choose the tags that best apply to this pantry:</div>
                        <input type="text" placeholder="requirements to receive assistance"
                               id="portals"/>
                    </div>*/}
          <div className="generalDiv">
            <MultiLingualContent contentString={'CURRENCY'} />
          </div>
          <div className="row--">
            <div className="rowTitle">
              <MultiLingualContent contentString={'Payments Accepted'} />
            </div>
            <div className="currencyTypes">
              {addInputCurrency('Medicare', 'mcr')}
              {addInputCurrency('Medicaid', 'mdc')}
              {addInputCurrency('PeachCare', 'pcr')}
              {addInputCurrency('Uninsured/Underinsured', 'un')}
              {addInputCurrency('Sliding Scale Fee', 'ssf')}
              {addInputCurrency('Self Pay', 'sp')}
            </div>
          </div>
          <div className="generalDiv">
            <MultiLingualContent contentString={'ADDITIONAL INFORMATION'} />
          </div>
          <div className="row--">
            <div
              className="-container"
              style={{ paddingLeft: 14 + 'px', marginTop: 10 + 'px' }}
            >
              <textarea rows="6" id="comment"></textarea>
            </div>
          </div>
          <div className="suggestButtons">
            <button onClick={handleHomeClick} className="cancel">
              <MultiLingualContent contentString={'Cancel'} />
            </button>
            <button onClick={handleSubmit} className="create">
              <i className="fa fa-spinner fa-spin"></i>
              <span>
                <MultiLingualContent contentString={'Create'} />
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
