import React from 'react';

export const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: '1px dotted pink',
    color: state.selectProps.menuColor,
    padding: 8,
    scrollbarTrackColor: 'red',
    zIndex: 10000,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 11,
    width: 160,
    textAlign: 'left',
    //backgroundColor: '#0B1E32'
  }),
  hover: (provided, state) => ({
    ...provided,
    fontSize: 11,
    width: 160,
    textAlign: 'left',
    backgroundColor: '#13293D',
    color: '#FFFFFF',
  }),
};

export const containerStyle = {
  width: '100%',
  height: window.innerHeight - 46,
};

export const Image = {
  Svg: function Svg(props) {
    return (
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size}
          height={props.size}
          viewBox="0 0 36 36"
        >
          <path
            id={'filter' + props.id + 'Path'}
            fill="#c7cacc"
            d={props.icon}
          />
        </svg>
      </div>
    );
  },
  SvgDouble: function Svg(props) {
    return (
      <div className="filterInfo">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.size}
          height={props.size}
          viewBox="0 0 36 36"
        >
          <path
            id={'filter' + props.id + 'Path'}
            fill={props.color}
            d={props.icon}
          />
        </svg>
        <label>{props.name}</label>
      </div>
    );
  },
  //create the svg for the map
};
