/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "../Popup/Popup.css";
import axios from "axios";
import Logo from "../../images/gnr_logo.svg";
import { baseUrl, encrypted_url, portal } from "../../services/fetch";
import MultiLingualContent from "../../services/LanguageContext/MultiLingualContent";
// import { consoleFF } from '../helpers';
baseUrl();

function Popup(props) {
  const [userInfo, setUserInfo] = useState({});
  const [showSurvey, setShowSurvey] = useState(true);
  const [displayThanks, setDisplayThanks] = useState(false);
  const [option1, setOption1] = useState([]);
  const [option2, setOption2] = useState("option2_0");
  //creating function to load ip address from the API
  const getIp = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    //console.log(res.data);
    setUserInfo(res.data);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getIp();
  }, []);

  const firstQuestion = (e) => {
    let values = option1;
    if (e.target.checked === true) {
      values.push(e.target.value);
    } else {
      values = values.filter((b) => b !== e.target.value);
    }
    setOption1(values);
  };
  const secondQuestion = (e) => {
    setOption2(e.target.value);
  };

  const addInputDescription = () => {
    if (option2 === "option2_2") {
      return (
        <textarea
          rows="3"
          id="comment"
          className="inputDescription"
          type="text"
          placeholder={MultiLingualContent({
            contentString:
              "Please tell us what information you need so we can try to add it to our list.",
          })}
        />
      );
    }
  };
  const addInput = (text, value, name) => {
    return (
      <label
        style={{
          // color: option1 === 'option1_2' ? '#00A0AF' : '#373a3c',
          // fontWeight: option1 === 'option1_2' ? 'bold' : 'normal',
          fontWeight: option1.includes(name) ? "bold" : "normal",
        }}
        className="labelOption"
      >
        <input
          className="inputOption"
          type="checkbox"
          name={name}
          value={value}
          onChange={firstQuestion}
        />
        {text}
      </label>
    );
  };

  function sendSurvey() {
    if (option2 === "option2_0") return;

    let comment = "";
    let question02 = document.querySelector(
      'input[name="question02"]:checked'
    ).value;
    if (question02 === "option2_2")
      comment = document.querySelector("#comment").value;

    let log = {
      ip: userInfo.IPv4,
      userAgent: navigator.userAgent,
      userInformation: userInfo,
    };

    let params = [
      "question01=" + option1,
      "question02=" +
        (document
          .querySelector('input[name="question02"]:checked')
          .value.split("_")[1] -
          1),
      "comment=" + comment,
      "log=" + JSON.stringify(log),
      "portal=" + portal,
    ];
    //consoleFF.log(params)
    //consoleFF.log(`${encrypted_url}/surveys?${params.join("&")}`)
    //return;
    const url = `${encrypted_url}/surveys?${params.join("&")}`;

    localStorage.setItem("surveyDisplay", "no");
    setDisplayThanks(true);

    return fetch(url, {
      method: "POST",
    })
      .then((res) => {
        //consoleFF.log(res)
      })
      .then((data) => {
        //consoleFF.log('REQ', url, data);
        // eslint-disable-next-line no-sequences
        return "Success: ", data;
      })
      .catch((error) => {
        //consoleFF.log('REQ', url, error);
        // eslint-disable-next-line no-sequences
        return "Error: ", error;
      });
  }

  function permanentlyHideSurvey() {
    localStorage.setItem("surveyDisplay", "no");
    setShowSurvey(false);
    props.setTrigger(false);
  }

  function remindLaterSurvey(e) {
    let days = e.target.innerHTML.includes("later") ? 3 : 1;
    const appointment = new Date();
    appointment.setDate(appointment.getDate() + days);
    localStorage.setItem(
      "surveyDisplayLater",
      appointment.getTime().toString()
    );
    setShowSurvey(false);
    props.setTrigger(false);
  }
  // console.log('Show trigger', trigger);
  // return showSurvey ? (
  return (
    props.trigger && (
      <div className={props.trigger ? "popup popup-active" : "popup"}>
        <div
          className={
            props.trigger ? "popup-inner popup-inner-active" : "popup-inner"
          }
        >
          <img
            aria-label="gnr logo"
            role="button"
            aria-pressed="false"
            className="Logo"
            src={Logo}
            alt="logo"
          />
          <div style={{ cursor: "pointer", zIndex: "50" }}>
            <svg
              onClick={remindLaterSurvey}
              className="close-btn"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 18 18"
            >
              <path
                fill="#000"
                fillRule="evenodd"
                d="M13.4 3.5l1.1 1.1-4.401 4.399 4.4 4.4-1.1 1.1L9 10.1l-4.4 4.4L3.5 13.4 7.9 9l-4.4-4.4L4.6 3.5l4.399 4.4 4.4-4.4z"
              />
            </svg>
          </div>
          {props.children}
          <div className="divQuestion">
            <label className="labelTitleQuestion">
              <MultiLingualContent
                contentString={
                  "What are the biggest barriers you have to getting the healthcare services you need? Check all that apply:"
                }
              />
            </label>
            <div className="checkBoxOptions">
              {addInput(
                <MultiLingualContent contentString={"cost"} />,
                "cost",
                "cost"
              )}
              {addInput(
                <MultiLingualContent contentString={"transportation"} />,
                "transportation",
                "transportation"
              )}
              {addInput(
                <MultiLingualContent contentString={"finding a provider"} />,
                "finding a provider",
                "finding a provider"
              )}
              {addInput(
                <MultiLingualContent contentString={"limited service hours"} />,
                "limited service hours",
                "limited service hours"
              )}
              {addInput(
                <MultiLingualContent contentString={"language barriers"} />,
                "language barriers",
                "language barriers"
              )}
              {addInput(
                <MultiLingualContent contentString={"other"} />,
                "other",
                "other"
              )}
            </div>
          </div>
          <div className="divQuestion">
            <label className="labelTitleQuestion">
              <MultiLingualContent
                contentString={"Did you find what you needed here today?"}
              />
            </label>
            <div className="divOptions">
              <div className="inputOptions">
                <label
                  style={{
                    // color: option2 === 'option2_1' ? '#00A0AF' : '#373a3c',
                    fontWeight: option2 === "option2_1" ? "bold" : "normal",
                  }}
                  className="labelOption"
                >
                  <input
                    className="inputOption"
                    type="radio"
                    name="question02"
                    value="option2_1"
                    checked={option2 === "option2_1"}
                    onChange={secondQuestion}
                  />
                  <MultiLingualContent contentString={"Yes"} />
                </label>
                <label
                  style={{
                    // color: option2 === 'option2_2' ? '#00A0AF' : '#373a3c',
                    fontWeight: option2 === "option2_2" ? "bold" : "normal",
                  }}
                  className="labelOption"
                >
                  <input
                    className="inputOption"
                    type="radio"
                    name="question02"
                    value="option2_2"
                    checked={option2 === "option2_2"}
                    onChange={secondQuestion}
                  />
                  <MultiLingualContent contentString={"No"} />
                </label>
              </div>
              {addInputDescription()}
            </div>
          </div>
          <div className="buttons">
            <button onClick={remindLaterSurvey} className="remindLater">
              <MultiLingualContent contentString={"Remind me later"} />
            </button>
            <button onClick={permanentlyHideSurvey} className="dontShowAgain">
              <MultiLingualContent contentString={"Don't show again"} />
            </button>
            <button onClick={sendSurvey} className="submit">
              <MultiLingualContent contentString={"Submit"} />
            </button>
          </div>
        </div>
        <div
          style={{ display: displayThanks ? "flex" : "none" }}
          className="popup-thanks"
        >
          <img
            aria-label="gnr logo"
            role="button"
            aria-pressed="false"
            className="Logo"
            src={Logo}
            alt="logo"
          />
          <h4 style={{ fontWeight: "900" }}>
            <MultiLingualContent contentString={"Thank you for your help!"} />
          </h4>
          <h6
            style={{
              fontSize: "14px",
              color: "grey",
              fontWeight: "500",
              width: "70%",
            }}
          >
            <MultiLingualContent
              contentString={
                "Your feedback has been successfully submitted. Your opinion is important for us!"
              }
            />
          </h6>
        </div>
      </div>
    )
  );
  // : (
  //   ''
  // );
}
export default Popup;
