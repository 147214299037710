/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { suggestClick } from "../Pages/Home/analytics";
// import ReactGa from 'react-ga';
// import { consoleFF } from '../Pages/helpers';
const pako = require("pako");
const _ = require("lodash");
const cryptojs = require("crypto-js");

// localhost needs to set DEV=true'
const env = window.location.host;
console.log(env);
const geoCodeUrl =
  "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAZpMZjbcD_8LjgYs8FbmebHA_g7sgS4aM&address=";
export const portal = 3; //gnr
export let debug;
export let encrypted_url;

/**
 *
 * @param type - can be default/encrypted
 * @returns {string}
 */
export const baseUrl = () => {
  switch (env) {
    case "localhost:3000":
      debug = true;
      encrypted_url = _.get(
        process.env,
        "REACT_APP_API_V2",
        // 'https://staging-api-v2.foodfinder.us'
        "http://localhost:8080"
      );
      // ReactGa.initialize('G-JG20WZWF2H'); //dev
      return _.get(
        process.env,
        "REACT_APP_API",
        "https://staging-api.foodfinder.us"
      );
    //   return _.get(process.env, 'REACT_APP_API', 'http://localhost:8080');
    case "gnr-dot-foodfinder-183216.uc.r.appspot.com":
      debug = true;
      encrypted_url = "https://staging-api-v2.foodfinder.us";
      // ReactGa.initialize('G-JG20WZWF2H'); //dev
      return "https://staging-api.foodfinder.us";
    case "prod":
      debug = false;
      encrypted_url =
        "https://api-v2-prod-dot-foodfinder-183216.uc.r.appspot.com";
      // ReactGa.initialize('G-JPEQR2BHPN'); //prod
      return "https://api.foodfinder.us";
    default: //prod
      debug = true;
      encrypted_url = _.get(
        process.env,
        "REACT_APP_API_V2",
        "https://api-v2.foodfinder.us"
      );
      // ReactGa.initialize('G-JPEQR2BHPN');
      return _.get(process.env, "REACT_APP_API", "https://api.foodfinder.us");
  }
};

function dataFetch(url, history, options) {
  return fetch(url, options).then((res) => {
    if (res.ok) return res.json();

    if (res.status === 401) {
      history.push({ pathname: `/` });
      return;
    }

    /*throw new Error(
			url + ':' + res.status + ' ' + res.statusText
		)*/
  });
}

function dataPost(url, data = null, history) {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status !== 200) {
        if (debug) console.log(res);
        alert("Something went wrong, try again Later!");
        return res.json();
      } else {
        alert(
          "Your listing on GNR Health Finder was successfully created. Our Admin team will review and push your listing live as soon as possible. Thank you for being a part of the GNR Health Finder network!"
        );
        if (data.pantry_provider_id === undefined) {
          suggestClick("new_resource", `${data.name} | ${data.address1}`);
        } else {
          suggestClick("edit_resource", `Pantry: ${data.pantry_provider_id}`);
        }
        suggestClick("create_location", "City: " + data.city);
        history.push(`/`);
        return res.json();
      }
    })
    .then((data) => {
      //console.log('REQ', url, data);

      // eslint-disable-next-line no-sequences
      return "Success: ", data;
    })
    .catch((error) => {
      //console.log('REQ', url, error);

      // eslint-disable-next-line no-sequences
      return "Error: ", error;
    });
}

export async function getDataPantryDetails(id, setDetails, setSecond = null) {
  const time = Date.now();
  let url = `${encrypted_url}/portal/tags/${id}?details=true&_time=${time}`;
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "EN";
  url = url.concat(`&lang=${lang.replaceAll('"', "")}`);

  await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async (response) => {
    if (response.ok) {
      const rawResult = await decrypt(response, time.toString());
      const result = JSON.parse(rawResult);
      setDetails(result[0]);
      setSecond && setSecond(result[0]);
    }
  });
}

export function useDataPantryDetails(id, history = null, dState = null) {
  //const domainHash = localStorage.getItem('domainHash')
  // const url = `${baseUrl()}/portal/tags/${id}?details=true`;
  const time = Date.now();
  let url = `${encrypted_url}/portal/tags/${id}?details=true&_time=${time}`;
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "EN";
  url = url.concat(`&lang=${lang.replaceAll('"', "")}`);
  url = url.concat(`&portal=${portal}`);
  const [data, setData] = useState({ loaded: false, data: dState });

  useEffect(() => {
    fetch(url, history).then(async (res) => {
      if (debug) console.log("REQ", url, res);
      const rawResult = await decrypt(res, time.toString());
      const result = JSON.parse(rawResult);
      if (id !== undefined) {
        Object.entries(result).forEach((f) => {
          if (f[1] === "") {
            delete result[f[0]];
          }
        });
      }
      setData({ loaded: true, data: result[0] });
    });
  }, []);

  return [data, setData];
}

function _arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return Buffer.from(binary, "binary").toString("base64");
}

export async function decrypt(response, time) {
  const encryptedBuffer = await response.arrayBuffer();
  const hash = cryptojs.MD5(time);
  const key = hash.toString(cryptojs.enc.Hex);
  const base64 = _arrayBufferToBase64(encryptedBuffer);
  const decryptedContent = cryptojs.AES.decrypt(base64, key).toString(
    cryptojs.enc.Latin1
  );
  const intArray = Uint8Array.from(Buffer.from(decryptedContent, "latin1"));
  const inflated = pako.inflate(intArray, { to: "string" });
  return inflated;
}

export function useDataPantries($coords, history = null, dState = null) {
  const time = Date.now();
  // const url = `${baseUrl()}/portal/tags?min_lat=33.8912499943749&max_lat=34.01911506256493&min_lon=-84.10045507902834&max_lon=-83.9090526437256&portal=2`;
  const url = `${encrypted_url}/portal/tags?min_lat=33.53791707865296&max_lat=33.665881674343865&min_lon=-84.0265275387233&max_lon=-83.6969376949733&portal=3&_time=${time}`;
  const [data, setData] = useState({ loaded: false, data: dState });

  useEffect(() => {
    if (localStorage.getItem("data")) {
      setData(JSON.parse(localStorage.getItem("data")));
    } else {
      fetch(url, history).then(async (res) => {
        if (debug) console.log("REQ", url, res);
        const result = await decrypt(res, time.toString());
        setData({ loaded: true, data: JSON.parse(result) });
      });
      // dataFetch(url, history).then((res) => {
      //   if (debug) console.log("REQ", url, res);

      //   setData({
      //     loaded: true,
      //     data: res,
      //   });
      // });
    }
  }, []);

  return [data, setData];
}

export function useFilters(history, dState, order = '?order=["name","ASC"]') {
  //const url = `${baseUrl()}/portal/filters?portal=2`
  const url = `${encrypted_url}/portal/filters/${portal}${order}`;

  const [filters, setFilters] = useState({ loaded: false, data: dState });

  useEffect(() => {
    dataFetch(url, history).then((res) => {
      if (debug) console.log("REQ", url, res);

      setFilters({ loaded: true, data: res });
    });
  }, []);

  return [filters, setFilters];
}

export function useCurrencies(history, dState) {
  const url = `${encrypted_url}/admin/providers/currency`;

  const [currency, setCurrency] = useState({ loaded: false, data: dState });

  useEffect(() => {
    dataFetch(url, history).then((res) => {
      if (debug) console.log("REQ", url, res);

      setCurrency({ loaded: true, data: res });
    });
  }, []);

  return [currency, setCurrency];
}

export function useLanguages(history, dState) {
  const url = `${encrypted_url}/languages/${portal}`;

  const [languages, setLanguages] = useState({ loaded: false, data: dState });

  useEffect(() => {
    dataFetch(url, history).then((res) => {
      if (debug) console.log("REQ", url, res);

      setLanguages({ loaded: true, data: res });
    });
  }, []);
  return [languages, setLanguages];
}

export function useCities(history, dState) {
  const url = `${encrypted_url}/cities/${portal}`;
  //const url = `https://staging-api.foodfinder.us/cities?state=IL`

  const [filters, setFilters] = useState({ loaded: false, data: dState });

  useEffect(() => {
    dataFetch(url, history).then((res) => {
      if (debug) console.log("REQ", url, res);

      setFilters({ loaded: true, data: res });
    });
  }, []);

  return [filters, setFilters];
}

export function createSuggest(data, history, dState, override = false) {
  if (override) {
    const url = `${encrypted_url}/suggest`;
    dataPost(url, data, history).then((res) => {
      if (debug) console.log("REQ", url, res);
    });
  } else {
    const url = `${encrypted_url}/suggest`;

    dataPost(url, data, history).then((res) => {
      if (debug) console.log("REQ", url, res);
    });
  }
}

export function geoCode(callback) {
  let address = ReactDOM.findDOMNode(
    document.getElementById("zipSearch")
  ).value;
  address = address.match(/^\d{5}(-\d{4})?/);
  if (address !== null) {
    if (parseInt(address) >= 30012 && parseInt(address) <= 30094) {
      dataFetch(geoCodeUrl + address[0], {}).then((data) => {
        if (data.results.length > 0) {
          localStorage.setItem("lat", data.results[0].geometry.location.lat);
          localStorage.setItem("lng", data.results[0].geometry.location.lng);
          return callback({
            lat: data.results[0].geometry.location.lat,
            lng: data.results[0].geometry.location.lng,
          });
        }
        alert("We only allow Newton & Rockdale County valid ZIP Codes");
        return callback(null);
      });
    } else {
      alert("We only allow Newton & Rockdale County ZIP Codes");
    }
  }
}
