/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from "react";
import Select from "react-select";
import ReactDOM from "react-dom";
import "../../CSS/header.css";
import "../../CSS/infoWindow.css";
import "../Filters/Filters.css";
import "../../CSS/InfoDetails.css";
import "../../CSS/ChatMFE.css";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
// import Logo from '../../images/group-28@2x.png';
import Help from "../../images/btn-help.svg";
import {
  useDataPantries,
  useFilters,
  geoCode,
  useCities,
  useCurrencies,
  encrypted_url,
  useLanguages,
  decrypt,
} from "../../services/fetch";
import {
  dataToPM,
  useStyles,
  StaticImages,
  getHoursInfoDetails,
} from "./helpers";
import {
  detailsClick,
  filterClick,
  pantryClick,
  zipCodeSearch,
} from "./analytics";
import ReactGa from "react-ga4";
import "../../CSS/filtersDoubleBand.css";
import { containerStyle, customStyles, Image } from "./consts";
import Popup from "../Popup/Popup";
import MultiLingualContent from "../../services/LanguageContext/MultiLingualContent";
import { consoleFF } from "../helpers";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { CDropdown, CDropdownMenu, CDropdownToggle } from "@coreui/react";
import LanguageIcon from "@mui/icons-material/Language";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useContext } from "react";
import { LanguageContext } from "../../services/LanguageContext/MultiLingualContext";
import { Alert, Divider, Snackbar, Typography } from "@mui/material";
// import { useLocation } from 'react-router-dom';

function MyComponent({ match, history }) {
  // const { search } = useLocation();
  // console.log(search.split('=')[1]);
  //const zipSearch = window.location.search.split("?zip=")[1]
  //const geoCodeUrl = "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyAZpMZjbcD_8LjgYs8FbmebHA_g7sgS4aM&address="

  useEffect(() => {
    //ReactGa.initialize('UA-173358873-2')
    // ReactGa.pageview('/');
    ReactGa.send({ hitType: "pageView", page: "/" });

    //displaySurvey()
  }, []);

  const classes = useStyles();

  const handleClick = (id, name, event) => {
    if (typeof id === "number") {
      history.push(`/details/${id}`);
      detailsClick(id, name);
      return;
    }
    detailsClick(selectedPlace.id);
    history.push(`/details/${selectedPlace.id}`);
  };

  // const handleHomeClick = () => {
  //   history.push(`/`);
  // };

  const handleNewPantryClick = (event) => {
    event.preventDefault();
    history.push("/new");
  };

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    navigator.geolocation.getCurrentPosition((pos) => {
      if (pos) {
        let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.coords.latitude},${pos.coords.longitude}&sensor=true&key=AIzaSyAZpMZjbcD_8LjgYs8FbmebHA_g7sgS4aM`;
        fetchData(url, {}).then((data) => {
          let address = data.results[0].formatted_address;
          if (address.includes(" GA") === true) {
            setCenter({
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            });
            SetCentered(true);
            handleDrag();
          }
        });
      }
      //console.log(pos)
    });
    //console.log("hey",mapRef.current.getBounds())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line no-unused-vars
  let filterItems = localStorage.getItem("filter");
  const [cities, setCities] = useCities(history, []);
  const [filters, setFilters] = useFilters(
    history,
    [],
    '?order=["name","DESC"]'
  );

  const [center, setCenter] = useState({
    lat: localStorage.getItem("lat")
      ? parseFloat(localStorage.getItem("lat"))
      : 33.5972047362801,
    lng: localStorage.getItem("lng")
      ? parseFloat(localStorage.getItem("lng"))
      : -83.85864271206313,
  }); //initial center
  const [data, setData] = useDataPantries(
    mapRef.current ? mapRef.current.getBounds() : "",
    history,
    []
  );
  const [currency, setCurrency] = useCurrencies(history, []);
  const [languageList, setLanguageList] = useLanguages(history, []);
  const [detail, setDetail] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [markerMap, setMarkerMap] = useState({});
  const [zoom, setZoom] = useState(13);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  // const [filter, setFilter] = useState(false);
  //the default value for this portal is the tag(2)
  const [toFilter, setToFilter] = useState([]);
  const [scheduleDone, setScheduleDone] = useState(false);

  const [infoDetails, setInfoDetails] = useState(false);

  const [centered, SetCentered] = useState(false);

  const [hiddenPantries, setHiddenPantries] = useState(false);

  /*if(zipSearch !== undefined && done == 0){
        done = 1;
        searchZipCode(zipSearch)
    }*/
  /**
   * Initialize Cities
   */
  useMemo(() => {
    if (cities.loaded) {
      let info = [];
      cities.data.forEach((city) => {
        info.push({
          value: city.id,
          label: city.city,
          lat: city.latitude,
          lng: city.longitude,
        });
      });
      setCities(info);
    }
  }, [cities]);

  /**
   * Initialize Center
   */
  useMemo(() => {
    if (centered) {
      try {
        let coords = mapRef.current.getBounds();
        if (
          coords.getNorthEast().lat() < 40.6331 &&
          coords.getNorthEast().lng() < -89.3985
        ) {
          // let url = `${baseUrl()}/portal/tags?min_lat=${coords
          const time = Date.now();
          let url = `${encrypted_url}/portal/tags?min_lat=${coords
            .getSouthWest()
            .lat()}&max_lat=${coords.getNorthEast().lat()}&min_lon=${coords
            .getSouthWest()
            .lng()}&max_lon=${coords
            .getNorthEast()
            .lng()}&portal=3&_time=${time}`;
          console.log(url);
          SetCentered(false);
          fetch(url, {}).then(async (res) => {
            const rawData = await decrypt(res, time.toString());
            const data = JSON.parse(rawData);
            setData({ loaded: true, data: data });
            filterMarkers("", { data: data });
          });
          // fetchData(url, {}).then((data) => {
          //   console.log(data);
          //   setData({
          //     loaded: true,
          //     data: data,
          //   });
          //   filterMarkers("", { data: data });
          // });
        }
      } catch (e) {}
    }
  }, [centered]);

  const detailsSchedule = (place) => {
    let json;
    try {
      json = JSON.parse(place.operating_days);
      setSchedule(json);
    } catch (e) {
      setScheduleDone(true);
      setSchedule({});
      return;
    }
    setScheduleDone(true);
  };

  //filters
  useMemo(() => {
    if (filters.loaded) {
      setFilters(filters.data);
      localStorage.setItem("filters", JSON.stringify(filters.data));
    }
  }, [filters]);

  //currencies
  useMemo(() => {
    if (currency.loaded) {
      setCurrency(currency.data);
    }
  }, [currency]);

  // languages
  const { language, toggleLanguage } = useContext(LanguageContext);
  const [selectLanguage, setSelectLanguage] = useState(
    localStorage.getItem("language")
      ? JSON.parse(localStorage.getItem("language"))
      : "EN"
  );

  const handleLanguageClick = (event) => {
    setSelectLanguage(
      languageList
        .find((lg) => lg.value === event.target.value)
        .tla.toUpperCase()
    );
    toggleLanguage(
      languageList
        .find((lg) => lg.value === event.target.value)
        .tla.toUpperCase()
    );
    localStorage.setItem(
      "language",
      JSON.stringify(
        languageList
          .find((lg) => lg.value === event.target.value)
          .tla.toUpperCase()
      )
    );
  };
  useMemo(() => {
    if (languageList.loaded) {
      let info = [];
      languageList.data.forEach((language) => {
        info.push({
          value: language.id,
          label: language.name,
          tla: language.tla,
        });
      });

      setLanguageList(info);
      setSelectLanguage(
        info.find((lg) => lg.tla.toUpperCase() === language).tla.toUpperCase()
      );
      // setSelectLanguage(
      //   info.find((lg) => +lg.value === selectLanguage).tla.toUpperCase()
      // );
      localStorage.setItem(
        "language",
        JSON.stringify(
          info.find((lg) => lg.tla.toUpperCase() === language).tla.toUpperCase()
        )
      );
    }
  }, [languageList]);

  useMemo(() => {
    if (localStorage.getItem("data") && filters) {
      setTimeout(function () {
        let ff = localStorage.getItem("filter");
        if (ff !== null) {
          let filters = JSON.parse(localStorage.getItem("filters"));
          ff = JSON.parse(ff);
          ff.forEach((id) => {
            let element = document.getElementById("filter" + id);
            element.style.backgroundColor = filters.find(
              (x) => x.id === id
            ).filter.color;
            ReactDOM.findDOMNode(
              document.getElementById("filter" + id + "Path")
            ).style.fill = "#FFFFFF";
            ReactDOM.findDOMNode(element).style.color = "#FFFFFF";
          });
          setToFilter(ff);
        } else {
          ff = "";
        }
        filterMarkers(ff, JSON.parse(localStorage.getItem("data")));
        setData(JSON.parse(localStorage.getItem("data")));
      }, 1000);
    }
  }, [localStorage.getItem("data")]);
  //pantries
  useMemo(() => {
    if (data.loaded && !localStorage.getItem("data")) {
      let ff = localStorage.getItem("filter");
      if (ff !== null) {
        let filters = JSON.parse(localStorage.getItem("filters"));
        ff = JSON.parse(ff);
        ff.forEach((id) => {
          let element = document.getElementById("filter" + id);
          element.style.backgroundColor = filters.find(
            (x) => x.id === id
          ).filter.color;
          //ReactDOM.findDOMNode(element).style.backgroundColor = filters.find(x => x.id === id).filter.color
          ReactDOM.findDOMNode(
            document.getElementById("filter" + id + "Path")
          ).style.fill = "#FFFFFF";
          ReactDOM.findDOMNode(element).style.color = "#FFFFFF";
          //changeBackground("filter"+ element, filters.find(x => x.id === element).filter.color, element)
        });
        setToFilter(ff);
      } else {
        ff = "";
      }

      filterMarkers(ff);
    }
  }, [data]);

  // const handleFilters = (event) => {
  //   if (event != undefined) event.preventDefault();
  //   if (filter === true) return setFilter(false);

  //   return setFilter(true);
  // };

  /**
   * City handlers
   */
  const [selectCity, setSelectCity] = useState(null);
  const [selectCityVal, setSelectCityVal] = useState(null);
  const handleCityClick = (event) => {
    setSelectCity(event.id);
    setSelectCityVal(event);
  };

  function citySearch() {
    document.getElementById("zipSearch").value = "";
    //console.log(event)
    setZoom(12);
    setZoom(13);
    setCenter({
      lat: selectCityVal.lat,
      lng: selectCityVal.lng,
    });
    setTimeout(handleDrag, 500);
  }

  const handleInfoDetails = (event) => {
    if (event !== undefined) event.preventDefault();
    if (infoDetails === true) return setInfoDetails(false);

    return setInfoDetails(true);
  };

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  const markerClickHandler = (event, place) => {
    if (scheduleDone) {
      setScheduleDone(false);
    }
    // Remember which place was clicked
    setSelectedPlace(place);
    pantryClick(place.id, place.name);

    detailsSchedule(place);

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false);
    }

    setInfoOpen(true);

    // If you want to zoom in a little on marker click
    //if (zoom < 13) {
    //    setZoom(13);
    //}
    console.log(mapRef.current.getZoom());
    // if you want to center the selected Marker
    //setCenter(place.pos)
  };

  const defaultMapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
  };

  function changeBackground(id, color, filter_id) {
    let element = document.getElementById(id);
    let remove = "";
    if (
      ReactDOM.findDOMNode(element).style.backgroundColor === "" ||
      ReactDOM.findDOMNode(element).style.backgroundColor === "white"
    ) {
      //console.log(tagsFilter[filter_id])
      toFilter.push(parseInt(filter_id));
      console.log(toFilter);
      localStorage.setItem("filter", JSON.stringify(toFilter));
      setToFilter(toFilter);
      filterItems = toFilter;
      ReactDOM.findDOMNode(element).style.backgroundColor = color;
      //ReactDOM.findDOMNode(document.getElementById(id + 'Span')).style.color = 'white';
      ReactDOM.findDOMNode(document.getElementById(id + "Path")).style.fill =
        "#FFFFFF";
      ReactDOM.findDOMNode(element).style.color = "#FFFFFF";
      ReactDOM.findDOMNode(element).style.border = "1px solid " + color;
      //analytics
      filterClick(
        filters
          .map((f) => {
            if (f.id === filter_id) return f.name;
          })
          .join("")
      );
    } else {
      remove = toFilter;
      remove = remove.filter((item) => {
        return item !== parseInt(filter_id);
      });
      console.log(remove);
      localStorage.setItem("filter", JSON.stringify(remove));
      setToFilter(remove);
      ReactDOM.findDOMNode(element).style.backgroundColor = "white";
      //ReactDOM.findDOMNode(document.getElementById(id + 'Span')).style.color = '#373a3c';
      ReactDOM.findDOMNode(document.getElementById(id + "Path")).style.fill =
        color;
      ReactDOM.findDOMNode(element).style.color = "#A1AAB4";
      ReactDOM.findDOMNode(element).style.border = "solid 1px " + color;
    }
    filterMarkers(remove);
  }

  /**
   * Map handle drag
   * make the request for the correspondent bounds
   */
  function handleDrag() {
    //localStorage.clear()
    const time = Date.now();
    let coords = mapRef.current.getBounds();
    // let url = `${baseUrl()}/portal/tags?min_lat=${coords
    let url = `${encrypted_url}/portal/tags?min_lat=${coords
      .getSouthWest()
      .lat()}&max_lat=${coords.getNorthEast().lat()}&min_lon=${coords
      .getSouthWest()
      .lng()}&max_lon=${coords.getNorthEast().lng()}&portal=3&_time=${time}`;
    localStorage.setItem("lat", mapRef.current.getCenter().lat());
    localStorage.setItem("lng", mapRef.current.getCenter().lng());
    fetch(url, {}).then(async (res) => {
      const rawData = await decrypt(res, time.toString());
      const data = JSON.parse(rawData);
      localStorage.setItem(
        "data",
        JSON.stringify({ loaded: true, data: data })
      );
      setData({ loaded: true, data: data });
      filterMarkers("", { data: data });
    });
    // fetchData(url, {}).then((data) => {
    //   localStorage.setItem(
    //     "data",
    //     JSON.stringify({ loaded: true, data: data })
    //   );
    //   setData({ loaded: true, data: data });
    //   filterMarkers("", { data: data });
    // });
  }

  function searchZipCode() {
    geoCode((res) => {
      if (res !== null) {
        setZoom(12);
        setZoom(13);
        setCenter({
          lat: res.lat,
          lng: res.lng,
        });
        setSelectCity(null);
        handleDrag();
        zipCodeSearch();
      }
    });
  }

  function fetchData(url, options) {
    return fetch(url, options).then((res) => {
      if (res.ok) return res.json();

      if (res.status === 401) {
        consoleFF.log({
          success: "false",
          url: url,
        });
        return;
      }
    });
  }

  function filterMarkers(filter_click = "", drag = "") {
    if (filter_click === "") {
      filter_click = toFilter;
    }
    if (drag === "") drag = data;

    let points = [];
    drag.data.map((point) => {
      if (!point.hasOwnProperty("filter_id") && filter_click === "") {
        points.push(point); //render in map
      } else {
        let filter = [];
        filter.push(point.filter_id);
        if (filter_click.length === 0) {
          points.push(point);
        } else {
          if (filter_click.some((id) => filter[0].includes(id))) {
            let newPoint = JSON.parse(JSON.stringify(point));
            // let newPoint = point;
            newPoint.filter_id = newPoint.filter_id.filter((filterId) =>
              filter_click.includes(filterId)
            );
            points.push(newPoint);
            // points.push(point);
          }
        }
      }
    });
    setDetail(points);
  }

  function _handleKeyPress(e) {
    if (e.key === "Enter") {
      searchZipCode();
    }
  }

  function _handleKeyPressInfoDetails(e) {
    if (e.key === "Enter") {
      handleInfoDetails();
    }
  }

  const [showSurvey, setShowSurvey] = useState(false);

  function displaySurvey(min = 0, max = 10) {
    /*if (localStorage.surveyDisplay != undefined && localStorage.surveyDisplay == 'no') return;
        if (localStorage.surveyDisplayLater != undefined && parseInt(localStorage.surveyDisplayLater) > new Date().getTime())
            return;

        setTimeout(() => {
            setShowSurvey(true)
        }, 30000)*/
    setShowSurvey(true);
  }

  const addWorkingDays = (day, text) => {
    let days = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];
    return days.map((d) => (
      <div
        className={classes.day_avaiablee}
        style={{ display: day[d].checked ? "block" : "none" }}
      >
        {/* <span className="dayy">{d}</span> */}
        <span className="dayy">
          {<MultiLingualContent contentString={d} />}
        </span>
        <span className="hourr">
          {dataToPM(day[d].hours, "from") +
            " - " +
            dataToPM(day[d].hours, "to")}
        </span>
      </div>
    ));
  };

  function handleZoom() {
    if (mapRef.current) {
      const time = Date.now();
      let coords = mapRef.current.getBounds();
      // let url = `${baseUrl()}/portal/tags?min_lat=${coords
      let url = `${encrypted_url}/portal/tags?min_lat=${coords
        .getSouthWest()
        .lat()}&max_lat=${coords.getNorthEast().lat()}&min_lon=${coords
        .getSouthWest()
        .lng()}&max_lon=${coords.getNorthEast().lng()}&portal=3&_time=${time}`;
      localStorage.setItem("lat", mapRef.current.getCenter().lat());
      localStorage.setItem("lng", mapRef.current.getCenter().lng());
      fetch(url, {}).then(async (res) => {
        const rawData = await decrypt(res, time.toString());
        const data = JSON.parse(rawData);
        localStorage.setItem(
          "data",
          JSON.stringify({ loaded: true, data: data })
        );
        data.length === 300
          ? setHiddenPantries(true)
          : setHiddenPantries(false);
        setData({ loaded: true, data: data });
        filterMarkers("", { data: data });
      });
      // fetchData(url, {}).then((data) => {
      //   localStorage.setItem(
      //     "data",
      //     JSON.stringify({ loaded: true, data: data })
      //   );
      //   data.length === 300
      //     ? setHiddenPantries(true)
      //     : setHiddenPantries(false);
      //   setData({ loaded: true, data: data });
      //   filterMarkers("", { data: data });
      // });
    }
  }

  return (
    <div>
      <div className="headerBar">
        {/* <div className="headerLeft">
                    <img onKeyPress={_handleKeyPressBackButton} aria-label="back button logo" tabIndex="0" role="button" aria-pressed="false" className="Logo" onClick={handleHomeClick} src={Logo} alt="logo"/>
                </div>*/}
        <div className="headerMiddle">
          <div className="searchForm">
            <label className="hideLabel" id="searchLabel" htmlFor="zipSearch">
              {/* ZipCode Search */}
              {<MultiLingualContent contentString={"ZipCode Search"} />}
            </label>
            <input
              onKeyPress={_handleKeyPress}
              id="zipSearch"
              type="text"
              pattern="\d{5}(-\d{4})?"
              placeholder={
                MultiLingualContent({
                  contentString: "Search for health sites by ZIP Code",
                })
                // <MultiLingualContent
                //   contentString={'Search for health sites by ZIP Code'}
                // />
              }
              //"Search for health sites by ZIP Code"
            />
            {/*<button aria-label="Search Zip Code" onClick={searchZipCode}>{StaticImages.Search}</button>*/}
            <div
              aria-label="Search Zip Code"
              tabIndex="0"
              aria-pressed="true"
              onClick={searchZipCode}
              className="buttonS"
            >
              {StaticImages.SearchIcon}
            </div>
          </div>
          <div className="citySearch">
            <Select
              value={selectCity}
              styles={customStyles}
              placeholder={
                <MultiLingualContent contentString={"Search by City"} />
              } //"Search by City"
              onChange={handleCityClick}
              options={cities}
            />
            <div
              aria-label="Search City"
              tabIndex="0"
              aria-pressed="true"
              /*onClick={() => handleCityClick(selectCity)}*/ onClick={() =>
                citySearch()
              }
              className="SearchCityButton"
            >
              {StaticImages.SearchIcon}
            </div>
          </div>
        </div>
        <div className="headerMiddleFlexEnd">
          <div className="headerRight" onClick={handleNewPantryClick}>
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                aria-label="Add New Resource"
                tabIndex="0"
                aria-pressed="true"
                className="newResource"
                onClick={handleNewPantryClick}
              >
                <MultiLingualContent contentString={"Add New Resource"} />
              </a>
            </div>
          </div>
          {/* <div
            aria-label="Add New Resource"
            tabIndex="0"
            aria-pressed="true"
            className="newResourceMobile"
            onClick={handleNewPantryClick}
          >
            {StaticImages.AddNewResourceMobile}
          </div> */}

          <CDropdown variant="dropdown" autoClose="outside">
            <CDropdownToggle caret={false} className="dropdownStyle">
              <LanguageIcon
                style={{
                  height: "16px",
                  width: "13px",
                  paddingBottom: "3px",
                  marginRight: "3px",
                }}
              />
              {selectLanguage}
            </CDropdownToggle>
            <CDropdownMenu
              style={{
                position: "absolute",
                inset: "0px 0px auto auto",
                transform: "translate(-8px, 30px)",
              }}
              // className="dropdownMenuStyle"
            >
              <FormControl>
                <FormLabel
                  style={{
                    fontSize: "14px",
                    fontFamily: "'Roboto'",
                    fontWeight: "700",
                    color: "#373a3c",
                    textAlign: "center",
                    marginBottom: "1.3rem",
                    justifyContent: "center",
                  }}
                >
                  <MultiLingualContent
                    contentString={"Please select one language:"}
                  />
                </FormLabel>
                <RadioGroup
                  className="radioGroupStyle"
                  onChange={(e) => handleLanguageClick(e)}
                >
                  {languageList.length &&
                    languageList.map((language, index) => (
                      <FormControlLabel
                        value={language.value}
                        label={
                          <Typography
                            className={
                              languageList.find(
                                (lg) => lg.value === language.value
                              ).tla === selectLanguage.toLowerCase()
                                ? "dropdownOptionSelected"
                                : "dropdownOptionNotSelected"
                            }
                          >
                            {language.label}
                          </Typography>
                        }
                        control={
                          <Radio
                            checked={
                              languageList.find(
                                (lg) => lg.value === language.value
                              ).tla === selectLanguage.toLowerCase()
                                ? true
                                : false
                            }

                            //color="#00A0AF"
                          />
                        }
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            </CDropdownMenu>
          </CDropdown>

          <div
            aria-label="Add New Resource"
            tabIndex="0"
            aria-pressed="true"
            className="newResourceMobile"
          >
            <CDropdown variant="dropdown" autoClose="outside">
              <CDropdownToggle
                caret={false}
                placement="top-start"
                className="dropdownStyleMobile"
              >
                <MoreVertIcon
                  style={{
                    //   height: '28px',
                    //   width: '28px',
                    //   backgroundColor: '#00a0af',
                    //   color: 'white',
                    //   borderRadius: '100%',
                    marginLeft: "-11px",
                    marginTop: "-6px",
                  }}
                />
              </CDropdownToggle>
              <CDropdownMenu
                style={{
                  position: "absolute",
                  inset: "0px 0px auto auto",
                  transform: "translate(-8px, 30px)",
                }}
                // className="dropdownMenuStyle"
              >
                <FormControl>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      fontFamily: "'Roboto'",
                      fontWeight: "700",
                      color: "#373a3c",
                      textAlign: "center",
                      marginBottom: "1.3rem",
                      justifyContent: "center",
                    }}
                  >
                    <MultiLingualContent contentString={"Resources"} />
                  </FormLabel>
                  <div
                    className="boxStyle"
                    aria-label="Add New Resource"
                    tabIndex="0"
                    aria-pressed="true"
                    // className="newResource"
                    onClick={handleNewPantryClick}
                    style={{ fontSize: "13px" }}
                  >
                    {StaticImages.AddNewResourceMobile}

                    <MultiLingualContent contentString={"Add New Resource"} />
                  </div>
                </FormControl>
                <Divider
                  className="dividerStyle"
                  variant="fullWidth"
                  textAlign="center"
                />
                <FormControl>
                  <FormLabel
                    style={{
                      fontSize: "14px",
                      fontFamily: "'Roboto'",
                      fontWeight: "700",
                      color: "#373a3c",
                      textAlign: "center",
                      marginBottom: "1.3rem",
                      justifyContent: "center",
                    }}
                  >
                    {window.innerWidth > 500 ? (
                      <MultiLingualContent
                        contentString={"Please select one language:"}
                      />
                    ) : (
                      <MultiLingualContent contentString={"Languages"} />
                    )}
                  </FormLabel>
                  <RadioGroup
                    className="radioGroupStyle"
                    onChange={(e) => handleLanguageClick(e)}
                  >
                    {languageList.length &&
                      languageList.map((language) => (
                        <FormControlLabel
                          value={language.value}
                          label={
                            <Typography
                              className={
                                languageList.find(
                                  (lg) => lg.value === language.value
                                ).tla === selectLanguage.toLowerCase()
                                  ? "dropdownOptionSelected"
                                  : "dropdownOptionNotSelected"
                              }
                            >
                              {language.label}
                            </Typography>
                          }
                          control={
                            <Radio
                              checked={
                                languageList.find(
                                  (lg) => lg.value === language.value
                                ).tla === selectLanguage.toLowerCase()
                                  ? true
                                  : false
                              }
                              //color="#00A0AF"
                            />
                          }
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </CDropdownMenu>
            </CDropdown>
          </div>
        </div>
      </div>
      <LoadScript googleMapsApiKey="AIzaSyBvc_8ED5fXs_okreYRV458qnGV4d0V4M0">
        <div className="filtersBand">
          <div></div>
          <div className="filterLit">
            {filters[0]
              ? filters.map((f) => (
                  <button
                    aria-label={f.name}
                    key={f.id}
                    onClick={() =>
                      changeBackground("filter" + f.id, f.color, f.id)
                    }
                    id={"filter" + f.id}
                    className="filtersDiv"
                    style={{ border: "solid 1px " + f.color }}
                  >
                    <Image.SvgDouble
                      color={f.color}
                      name={MultiLingualContent({ contentString: f.name })}
                      size="20"
                      icon={f.icon}
                      id={f.id}
                    />
                  </button>
                ))
              : ""}
          </div>
        </div>
        <div className="InfoOpen">
          <div
            onKeyPress={_handleKeyPressInfoDetails}
            onClick={handleInfoDetails}
            role="button"
            tabIndex="0"
            aria-pressed="true"
            aria-label="Open Info Details Tab"
            className="openImg"
          >
            {StaticImages.MapListButton}
          </div>
        </div>
        <div className="Infos">
          <div className="InfoFloat">
            <div
              className="detailsInfo"
              style={{ display: infoDetails ? "block" : "none" }}
            >
              <div className="InfoHeader">
                <div
                  onKeyPress={_handleKeyPressInfoDetails}
                  aria-label="Close Filters Tab"
                  role="button"
                  tabIndex="0"
                  aria-pressed="true"
                  className="InfoCloseImg"
                  onClick={handleInfoDetails}
                >
                  {StaticImages.CloseButton}
                </div>
              </div>
              <div className="InfoDetails">
                {detail.length && filters.length ? (
                  detail.map((point) => (
                    <button
                      aria-label={point.name}
                      onClick={() => handleClick(+point.id, point.name)}
                      key={point.id}
                      className="toInfo"
                    >
                      <div className="toInfoHeader">
                        {!point.hasOwnProperty("filter_id") ? (
                          // eslint-disable-next-line jsx-a11y/alt-text
                          <img src={StaticImages.DefaultIconMap} />
                        ) : (
                          filters.map(
                            (f) =>
                              // point.filter_id.map((filterId) => {
                              //   if (filterId === +f.id) {
                              //     return (
                              //       <img
                              //         key={point.id + point.filter_id}
                              //         src={f.map_icon}
                              //       />
                              //     );
                              //   }
                              // })
                              point.filter_id.some((id) => id === +f.id) && (
                                // point.filter_id == f.id
                                // eslint-disable-next-line jsx-a11y/alt-text
                                <img
                                  key={
                                    point.id +
                                    point.filter_id.find((id) => id === +f.id)
                                  }
                                  src={f.map_icon}
                                />
                              )
                          )
                        )}
                        <div>{point.name}</div>
                      </div>
                      <div className="toInfoHoursDays">
                        {getHoursInfoDetails(point)}
                      </div>
                      <div className="toInfoCurrency">
                        {point.hasOwnProperty("currency") &&
                          point.currency.length > 0 &&
                          currency.length > 0 && (
                            <div className={classes.pCurrency}>
                              <MultiLingualContent contentString={"Accepts:"} />
                              {/* Accepts: */}
                              <div className="currencies">
                                {point.currency.split(",").map((cur) => (
                                  <div
                                    key={"days" + cur}
                                    className={classes.currency}
                                  >
                                    <label className={classes.currencyText}>
                                      {currency.find((o) => o.tla === cur).name}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                      </div>
                    </button>
                  ))
                ) : (
                  <div>
                    {" "}
                    <MultiLingualContent contentString={"Loading..."} />{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <GoogleMap
          onLoad={onMapLoad}
          onDragEnd={() => handleDrag()}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          options={defaultMapOptions}
          onZoomChanged={() => handleZoom()}
        >
          <div onClick={displaySurvey} className="helpButton">
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={Help} />
          </div>
          {
            detail.length > 0 &&
              filters.length > 0 &&
              detail.map((point) =>
                !point.hasOwnProperty("filter_id") ||
                point.filter_id.some((filter) => filter === null) ? (
                  <Marker
                    key={point.id}
                    position={{ lat: point.latitude, lng: point.longitude }}
                    onClick={(event) => markerClickHandler(event, point)}
                    onLoad={(marker) => markerLoadHandler(marker, point)}
                    icon={{
                      url: "https://res.cloudinary.com/dsgfmbsii/image/upload/v1597340578/icons/6-snap-wic-offices-2_1_oy3chq.svg",
                    }}
                  ></Marker>
                ) : (
                  point.filter_id.map((filterId) => (
                    <Marker
                      // key={point.id}
                      key={
                        point.id + +filters.find((f) => +f.id === filterId).id
                      }
                      position={{
                        lat: point.latitude,
                        lng: point.longitude,
                      }}
                      onClick={(event) => markerClickHandler(event, point)}
                      onLoad={(marker) => markerLoadHandler(marker, point)}
                      icon={{
                        url: filters.find((f) => +f.id === filterId).map_icon,
                      }}
                    ></Marker>
                  ))
                  // filters.map(
                  //   (f) =>
                  //     point.filter_id == f.id && (
                  //       <Marker
                  //         key={point.id}
                  //         position={{
                  //           lat: point.latitude,
                  //           lng: point.longitude,
                  //         }}
                  //         onClick={(event) => markerClickHandler(event, point)}
                  //         onLoad={(marker) => markerLoadHandler(marker, point)}
                  //         icon={{ url: f.map_icon }}
                  //       ></Marker>
                  //     )
                  // )
                )
              ) //detail length
          }
          {/* own location */}
          {/* {centered && (
            <Marker
              //   key={point.id}
              position={{ lat: center.lat, lng: center.lng }}
              //   onClick={(event) => markerClickHandler(event, point)}
              //   onLoad={(marker) => markerLoadHandler(marker, point)}
              //   icon={{
              //     url: 'https://res.cloudinary.com/dsgfmbsii/image/upload/v1597340578/icons/6-snap-wic-offices-2_1_oy3chq.svg',
              //   }}
            ></Marker>
          )} */}

          {infoOpen && selectedPlace && scheduleDone && schedule && (
            <InfoWindow
              anchor={markerMap[selectedPlace.id]}
              onCloseClick={() => setInfoOpen(false)}
            >
              <div className={classes.provider_info_containerr}>
                <div className={classes.providerr}>
                  {/* eslint-disable jsx-a11y/anchor-has-content */}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className="linkPopup" onClick={handleClick}></a>
                  {/* eslint-enable jsx-a11y/anchor-has-content */}

                  <h4 className="title">{selectedPlace.name}</h4>
                  <div className="time">
                    {!schedule.hasOwnProperty("monday") ? (
                      <div className="time">
                        <p>{selectedPlace.operating_days}</p>
                        <p>{selectedPlace.operating_hours}</p>
                        {selectedPlace.hasOwnProperty("currency") &&
                          selectedPlace.currency.length > 0 &&
                          currency.length > 0 && (
                            <div className={classes.pCurrency}>
                              <MultiLingualContent contentString={"Accepts:"} />
                              <div className="currencies">
                                {selectedPlace.currency
                                  .split(",")
                                  .map((cur) => (
                                    <div key={cur} className={classes.currency}>
                                      <label className={classes.currencyText}>
                                        {
                                          currency.find((o) => o.tla === cur)
                                            .name
                                        }
                                      </label>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )}
                      </div>
                    ) : (
                      <div className="time">{addWorkingDays(schedule, "")}</div>
                    )}
                    {selectedPlace.hasOwnProperty("currency") &&
                      selectedPlace.currency.length > 0 && (
                        <div
                          style={{
                            display: schedule.hasOwnProperty("monday")
                              ? "flex"
                              : "none",
                          }}
                          className={classes.pCurrency}
                        >
                          <MultiLingualContent contentString={"Accepts:"} />
                          <div className="currencies">
                            {selectedPlace.currency.split(",").map((cur) => (
                              <div
                                key={"days" + cur}
                                className={classes.currency}
                              >
                                <label className={classes.currencyText}>
                                  {currency.find((o) => o.tla === cur).name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="details">
                    {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className="detail" onClick={handleClick}>
                      {StaticImages.Arrow}
                    </a>
                  </div>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
      <Popup trigger={showSurvey} setTrigger={setShowSurvey} />
      <Snackbar open={hiddenPantries} autoHideDuration={5000}>
        <Alert severity="info"> More pantries available. Please zoom in.</Alert>
      </Snackbar>
    </div>
  );
}

export default React.memo(MyComponent);
