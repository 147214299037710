import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Details from './Pages/Details/Details';
import Home from './Pages/Home/Map';
import NewPantry from './Pages/NewPantry/NewPantry';

export default function Routes() {
  //localStorage.clear()
  delete localStorage.filters;
  delete localStorage.filter;
  delete localStorage.lng;
  delete localStorage.lat;
  delete localStorage.data;

  //const pathname =  window.location.pathname
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/details/:id" component={Details} />
        <Route path="/new" component={NewPantry} />
        <Route path="/suggest/:id" component={NewPantry} />
      </Switch>
    </BrowserRouter>
  );
}
